const rangeSelectorButtons = [{
    type: 'month',
    count: 1,
    text: 'М',
    title: '1 месяц'
}, {
    type: 'month',
    count: 3,
    text: '3м.',
    title: '3 месяца'
}, {
    type: 'month',
    count: 6,
    text: '6м.',
    title: '6 месяцев'
}, {
    type: 'ytd',
    text: 'Т.Г.',
    title: 'Текущий год'
}, {
    type: 'year',
    count: 1,
    text: 'Год',
    title: 'Год'
}, {
    type: 'all',
    text: 'Все',
    title: 'Весь период'
}];

//Сводный график доходности брокерских счетов
const accountsProfit = {
    capital: {
        chart: {
            type: 'area'
        },
        rangeSelector: {
            selected: 4,
            floating: true,
            buttons: rangeSelectorButtons
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            headerFormat: '<b>{point.key}</b><br/>',
            /*
            formatter: function () {
                return this.points.reduce(function (s, point) {
                    return s + '<br/>' + point.series.name + ': ' +
                        point.y + 'm';
                }, '<b>' + this.x + '</b>');
            },
            */
            /*
            formatter: function() {
                console.log(this);
            },
            */
            /*
            pointFormat: '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">{series.name}</div>' +
                    '<div class="h5 text-success mb-1">{point.y}</div>' +
                    '</div>',
            */
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' ₸',
            split: false,
            shared: true
        },
        caption: {
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            area: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            nameTemplate: 'Собственные средства, ',
            data: []
        }, {
            nameTemplate: 'Оценка ЦБ, ',
            color: 'rgba(40, 149, 72, 0.87)',
            data: []
        }, {
            nameTemplate: 'Оценка БС (ДС + ЦБ), ',
            color: 'rgba(40, 72, 149, 0.87)',
            data: []
        }],
        xAxis: {
            //visible: false
            //categories: [],
            //type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
            //tickPixelInterval: 10
        },
        yAxis: {
            title: null
        }
    },
    profit: {
        chart: {
            type: 'area'
        },
        rangeSelector: {
            selected: 4,
            floating: true,
            buttons: rangeSelectorButtons
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            formatter: function() {
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">' + this.series?.name + '</div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                    '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                    '</div>';
            },
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' %',
            split: false
        },
        caption: {
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            area: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            name: 'Прибыль ЦБ, %',
            data: []
        }, {
            name: 'Прибыль БС, %',
            color: 'rgba(40, 149, 72, 0.87)',
            data: []
        }],
        xAxis: {
            //visible: false
            //categories: [],
            //type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
        },
        yAxis: {
            title: null
        }
    },
};

//График доходности брокерского счета
const accountProfit = {
    capital: {
        chart: {
            type: 'area'
        },
        rangeSelector: {
            selected: 4,
            floating: true,
            buttons: rangeSelectorButtons
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            headerFormat: '<b>{point.key}</b><br/>',
            /*
            formatter: function () {
                return this.points.reduce(function (s, point) {
                    return s + '<br/>' + point.series.name + ': ' +
                        point.y + 'm';
                }, '<b>' + this.x + '</b>');
            },
            */
            /*
            formatter: function() {
                console.log(this);
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">' + this.series?.name + '</div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                    '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                    '</div>';
            },
            */
            /*
            pointFormat: '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">{series.name}</div>' +
                    '<div class="h5 text-success mb-1">{point.y}</div>' +
                    '</div>',
            */
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' ₸',
            split: false,
            shared: true
        },
        caption: {
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            /*
            series: {
                compare: 'percent',
                showInNavigator: true
            },
            */
            area: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            nameTemplate: 'Собственные средства, ',
            data: []
        }, {
            nameTemplate: 'Оценка ЦБ, ',
            color: 'rgba(40, 149, 72, 0.87)',
            data: []
        }, {
            nameTemplate: 'Оценка БС (ДС + ЦБ), ',
            color: 'rgba(40, 72, 149, 0.87)',
            data: []
        }],
        xAxis: {
            //visible: false
            //categories: [],
            //type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
            //tickPixelInterval: 10
        },
        yAxis: {
            title: null
        }
    },
    profit: {
        chart: {
            type: 'area'
        },
        rangeSelector: {
            selected: 4,
            floating: true,
            buttons: rangeSelectorButtons
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            formatter: function() {
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">' + this.series?.name + '</div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                    '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                    '</div>';
            },
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' %',
            split: false
        },
        caption: {
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            area: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            name: 'Прибыль ЦБ, %',
            data: []
        }, {
            name: 'Прибыль БС, %',
            color: 'rgba(40, 149, 72, 0.87)',
            data: []
        }],
        xAxis: {
            //visible: false
            //categories: [],
            //type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
            //tickPixelInterval: 10
        },
        yAxis: {
            title: null
        }
    },
};

//График выплаты купонов и амортизации
const accountCoupon = {
    chart: {
        type: 'column'
    },
    rangeSelector: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    },
    navigator: {
        enabled: false
    },
    title: null,
    tooltip: {
        className: 'popover',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        borderWidth: 0,
        headerFormat: '<b>{point.x}</b><br/>',
        footerFormat: '<b>Итого: {point.total:,.2f} {point.series.tooltipOptions.valueSuffix}</b>',
        //pointFormat: '{series.name}: {point.y}<br/>Итого: {point.stackTotal}',
        /*
        formatter: function () {
            return this.points.reduce(function (s, point) {
                return s + '<br/>' + point.series.name + ': ' +
                    point.y + 'm';
            }, '<b>' + this.x + '</b>');
        },
        */
        /*
        pointFormat: '<div class="popover-body p-1">' +
                '<div class="text-muted pb-1">{series.name}</div>' +
                '<div class="h5 text-success mb-1">{point.y}</div>' +
                '</div>',
        */
        useHTML: true,
        valueDecimals: 2,
        valueSuffix: ' ₸',
        split: false,
        shared: true
    },
    caption: {
    },
    legend: {
        enabled: true,
        reversed: true
    },
    plotOptions: {
        /*
        series: {
            compare: 'percent',
            showInNavigator: true
        },
        */
        column: {
            stacking: 'normal',
            dataLabels: {
                //enabled: true
            }
        }
    },
    series: [{
        nameTemplate: 'Амортизация / Погашение, ',
        color: 'rgba(205, 205, 205, 1)',
        data: []
    }, {
        nameTemplate: 'Купоны, ',
        color: 'rgba(20, 108, 67, 1)',
        data: []
    }],
    xAxis: {
        crosshair: {
            color: 'rgba(66, 47, 138, 0.87)',
            dashStyle: 'Dash',
            //snap: false,
            width: 1,
        },
        categories: []
    },
    yAxis: {
        title: null,
        labels: {
            format: '${text}' // The $ is literally a dollar unit
        },
    }
};

//График доходности брокерских счетов аккаунта
/*
const accountsProfit = {
    capital: {
        chart: {
            type: 'areaspline'
        },
        rangeSelector: {
            selected: 4,
            floating: true,
            buttons: rangeSelectorButtons
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            //footerFormat: '{this.series.name}',
            formatter: function() {
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">' + this.series.name + '</div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series.tooltipOptions.valueSuffix + '</div>' +
                    '<div class="р">' + this.x + '</div>' +
                    '</div>';
            },
            //headerFormat: 'series.name',
            //pointFormat: '{this.point.name}',
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' ₸'
        },
        caption: {
            //text: 'TEST'
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            areaspline: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            nameTemplate: 'Собственные средства, ',
            data: []
        }, {
            nameTemplate: 'Оценка ЦБ, ',
            color: 'rgba(40, 149, 72, 0.87)',
            data: []
        }, {
            nameTemplate: 'Оценка БС (ДС + ЦБ), ',
            color: 'rgba(40, 72, 149, 0.87)',
            data: []
        }],
        xAxis: {
            //visible: false
            categories: [],
            type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
            //tickPixelInterval: 10
        },
        yAxis: {
            title: null
        }
    },
    profit: {
        chart: {
            type: 'areaspline'
        },
        title: null,
        tooltip: {
            className: 'popover',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            borderWidth: 0,
            //footerFormat: '{this.series.name}',
            formatter: function() {
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1">' + this.series.name + '</div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' %</div>' +
                    '<div class="р">' + this.x + '</div>' +
                    '</div>';
            },
            //headerFormat: 'series.name',
            //pointFormat: '{this.point.name}',
            useHTML: true,
            valueDecimals: 2,
            valueSuffix: ' ₸'
        },
        caption: {
            //text: 'TEST'
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            areaspline: {
                //fillOpacity: 0.5,
                color: 'rgba(33, 57, 77, 0.87)',
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                    stops: [
                        [0, 'rgba(20, 108, 67, 0.1)'], // start
                        [1, 'rgba(255, 255, 255, 0)'] // end
                    ]
                }
            },
        },
        series: [{
            name: 'Прибыль ЦБ, %',
            data: []
        }, {
            name: 'Прибыль БС, %',
            data: []
        }],
        xAxis: {
            //visible: false
            categories: [],
            type: 'date',
            crosshair: {
                color: 'rgba(66, 47, 138, 0.87)',
                dashStyle: 'Dash',
                snap: false,
                width: 1,
            },
            //tickPixelInterval: 10
        },
        yAxis: {
            title: null
        }
    },
};
*/
// История цены ЦБ
const assetHistory = {
    chart: {
        type: 'area'
    },
    accessibility: {
        enabled: false
    },
    rangeSelector: {
        selected: 4,
        floating: true,
        buttons: rangeSelectorButtons,
        //inputEnabled: false
    },
    title: null,
    tooltip: {
        className: 'popover',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        borderWidth: 0,
        /*
        formatter: function() {
            console.log(this.series);
            return '<div class="popover-body p-1">' +
                '<div class="text-muted pb-1">' + this.series.name + '</div>' +
                '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series.tooltipOptions.valueSuffix.symbol + '</div>' +
                '<div class="р">' + this.x + '</div>' +
                '</div>';
        },
        */
        formatter: function() {
            if (this.series.type === 'flags') {
                let content = '<div class="popover-body p-1">';
                this.point.items.forEach(item => {
                    if (item.action === 'buy') {
                        content += '<div class="text-muted pb-1">Покупка: ' + item.quantity + ' шт.</div>';
                    }
                    if (item.action === 'sale') {
                        content += '<div class="text-muted pb-1">Продажа: ' + item.quantity + ' шт.</div>';
                    }
                });
                content += '<div class="h3 text-success mb-1">' + this.point.price + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                    '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                    '</div>';
                return content;
            } else {
                return '<div class="popover-body p-1">' +
                    '<div class="text-muted pb-1"><b>' + this.series?.name + '</b></div>' +
                    '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                    '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                    '</div>';
            }
        },
        useHTML: true,
        valueDecimals: 2,
        valueSuffix: ' ₸',
        split: false
    },
    caption: {
        //text: 'TEST'
    },
    legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        itemStyle: {
            color: '#333333',
            cursor: 'default',
            fontSize: '16px',
            fontWeight: '300',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center'
        },
        itemMarginBottom: 24,
        symbolHeight: 24,
        symbolPadding: 12,
        symbolRadius: 0,
        symbolWidth: 24,
    },
    plotOptions: {
        area: {
            //fillOpacity: 0.5,
            color: 'rgba(33, 57, 77, 0.87)',
            fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                stops: [
                    [0, 'rgba(20, 108, 67, 0.1)'], // start
                    [1, 'rgba(255, 255, 255, 0)'] // end
                ]
            },
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
        },
        flags: {
            accessibility: {
                exposeAsGroupOnly: true,
            }
        },
        allowPointSelect: false,
    },
    series: [{
        nameTemplate: 'Цена актива ',
        id: 'dataseries',
        type: 'area',
        //gapSize: 5,
        threshold: null,
        data: []
    }, {
        name: 'Покупка актива',
        title: '+',
        type: 'flags',
        onSeries: 'dataseries',
        shape: 'circlepin',
        data: []
    }, {
        name: 'Продажа актива',
        title: '-',
        type: 'flags',
        onSeries: 'dataseries',
        shape: 'circlepin',
        data: []
    }],
    xAxis: {
        gapGridLineWidth: 0,
        crosshair: {
            color: 'rgba(66, 47, 138, 0.87)',
            dashStyle: 'Dash',
            snap: false,
            width: 1,
        },
    },
    yAxis: {
        title: null
    }
};

// История цены облигации
const bondHistory = {
    chart: {
        //type: 'area',
        height: 600
    },
    accessibility: {
        enabled: false
    },
    rangeSelector: {
        selected: 4,
        floating: true,
        buttons: rangeSelectorButtons,
        //inputEnabled: false
    },
    title: null,
    tooltip: {
        className: 'popover',
        backgroundColor: '#ffffff',
        borderRadius: 10,
        borderWidth: 0,
        formatter: function() {
            console.log(this);
            let content = '<div class="popover-body p-1">';
            this.points.forEach(item => {
                content += '<div class="text-muted pb-1">' + item.series.name + ': ' + item.y.toFixed(2) + '</div>';
            });
            content += '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>';
            /*
            content += '<div class="h3 text-success mb-1">' + this.point.price + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                '</div>';
            */
            return content;
            /*
            return '<div class="popover-body p-1">' +
                '<div class="text-muted pb-1">' + this.series?.name + '</div>' +
                '<div class="h3 text-success mb-1">' + this.y + ' ' + this.series?.tooltipOptions?.valueSuffix + '</div>' +
                '<div class="р">' + new Date(this.x).toLocaleDateString('ru-RU') + '</div>' +
                '</div>';
            */
        },
        useHTML: true,
        valueDecimals: 2,
        valueSuffix: ' ₸',
        split: false,
        shared: true
    },
    caption: {
        //text: 'TEST'
    },
    legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        itemStyle: {
            color: '#333333',
            cursor: 'default',
            fontSize: '16px',
            fontWeight: '300',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center'
        },
        itemMarginBottom: 24,
        symbolHeight: 24,
        symbolPadding: 12,
        symbolRadius: 0,
        symbolWidth: 24,
    },
    plotOptions: {
        area: {
            //fillOpacity: 0.5,
            color: 'rgba(33, 57, 77, 0.87)',
            fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.5 },
                stops: [
                    [0, 'rgba(20, 108, 67, 0.1)'], // start
                    [1, 'rgba(255, 255, 255, 0)'] // end
                ]
            },
            /*
            events: {
                legendItemClick: function () {
                    return false;
                }
            }
            */
        },
        flags: {
            accessibility: {
                exposeAsGroupOnly: true,
            }
        },
        allowPointSelect: false,
    },
    series: [{
        nameTemplate: 'Цена облигации, %',
        id: 'dataseries',
        type: 'area',
        //gapSize: 5,
        //threshold: null,
        data: [],
        yAxis: 0
    /*
    }, {
        name: 'Покупка актива',
        title: '+',
        type: 'flags',
        onSeries: 'dataseries',
        shape: 'circlepin',
        data: []
    }, {
        name: 'Продажа актива',
        title: '-',
        type: 'flags',
        onSeries: 'dataseries',
        shape: 'circlepin',
        data: []
    */
    }, {
        nameTemplate: 'Доходность 1, %',
        color: 'rgba(40, 149, 72, 0.87)',
        id: 'dataseries-1',
        type: 'area',
        //gapSize: 5,
        //threshold: null,
        data: [],
        yAxis: 0
    }, {
        nameTemplate: 'Доходность 2, %',
        color: 'rgba(40, 72, 149, 0.87)',
        id: 'dataseries-2',
        type: 'area',
        //gapSize: 5,
        //threshold: null,
        data: [],
        yAxis: 0
    }, {
        nameTemplate: 'Общая доходность, %',
        color: 'rgba(149, 40, 72, 0.87)',
        id: 'dataseries-3',
        type: 'area',
        //gapSize: 5,
        //threshold: null,
        data: [],
        yAxis: 0
    }, {
        nameTemplate: 'Объемы торгов, млн',
        type: 'column',
        id: 'dataseries-delta',
        data: [],
        yAxis: 1
    /*
    }, {
        type: 'macd',
        id: 'oscillator',
        linkedTo: 'dataseries-1',
        yAxis: 1
    */
    }],
    xAxis: {
        gapGridLineWidth: 0,
        crosshair: {
            color: 'rgba(66, 47, 138, 0.87)',
            dashStyle: 'Dash',
            snap: false,
            width: 1,
        },
    },
    yAxis: [{
        height: '90%'
    }, {
        top: '90%',
        height: '10%'
    }],
};

export {
    accountsProfit,
    accountProfit,
    accountCoupon,
    assetHistory,
    bondHistory
};