import { app as settings } from '@/settings';
import { REST, RESTError } from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static get serviceName() {
        return 'finprogress';
    }

    static obtainToken(params) {
        return this._post(`user/token/create`, {}, params).then((data) => {
            let tokens = data;
            return tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось авторизоваться', { no_redirect: true });
        });
    }

    static refreshToken(token) {
        return this._post(`token/refresh`, {}, { refresh: token }).then((data) => {
            let tokens = data;
            return tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновит токен');
        });
    }

    static createUser(params) {
        return this._post(`user/create_user`, {}, params, false).then((data) => {
            let tokens = data;
            return tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать пользователя');
        });
    }

    static updateUser(params) {
        return this._put(`user/update_user`, {}, params).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить данные пользователя');
        });
    }

    static updateUserParticial(params) {
        return this._patch(`user/update_user`, {}, params).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить данные пользователя');
        });
    }

    static resetUser(params) {
        return this._post(`user/password_reset_user`, {}, params, false).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось сбросить пароль пользователя');
        });
    }

    static getUser() {
        return this._get(`user`, {}).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить пользователя');
        });
    }

    static getUserVkCode() {
        return this._get(`user/vk`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данных VK');
        });
    }

    static getUserVkCallback(params) {
        return this._post(`user/vk/callback`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данных VK CallBack');
        });
    }

    /* Notifications */
    static getNotificationsList(params) {
        return this._get(`notification/notifications`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить уведомления пользователя');
        });
    }

    static setNotificationsSeen(id) {
        return this._post(`notification/notifications/${id}/seen`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось изменить статус прочтения уведомления пользователя');
        });
    }

    static setNotificationsSeenAll() {
        return this._post(`notification/notifications/set_seen`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось изменить статус прочтения уведомлений пользователя');
        });
    }

    /* Reminders */
    static getRemindersMyList(params) {
        return this._get(`notification/reminders/my`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить напоминания пользователя');
        });
    }

    static getRemindersTrustList(params) {
        return this._get(`notification/reminders/trust`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить напоминания пользователя');
        });
    }

    static getReminder(params) {
        let id = params.reminderId;
        return this._get(`notification/reminders/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить напоминание пользователя');
        });
    }

    static addReminder(params) {
        delete params.reminderId;
        return this._post(`notification/reminders`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить напоминание пользователя');
        });
    }

    static updateReminder(params) {
        let id = params.reminderId;
        delete params.reminderId;
        return this._patch(`notification/reminders/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить напоминание пользователя');
        });
    }

    static deleteReminder(params) {
        let id = params.reminderId;
        return this._delete(`notification/reminders/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить напоминание пользователя');
        });
    }

    /* Profile */
    static getProfileCurrencyList() {
        return this._get(`user/currency`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить валюты пользователя');
        });
    }
    static getProfileCurrencyListAlt() {
        return this._get(`user/currency_profile`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить валюты пользователя');
        });
    }

    static addProfileCurrency(params) {
        return this._post(`user/currency`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить валюту пользователя');
        });
    }

    static addProfileCurrencyAlt(params) {
        return this._post(`user/currency_profile/add_currency`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить валюту пользователя');
        });
    }

    static changeProfileCurrency(params) {
        return this._put(`user/currency/change`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось изменить валюту пользователя');
        });
    }

    static deleteProfileCurrency(params) {
        return this._delete(`user/currency/delete`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить валюту пользователя');
        });
    }

    static deleteProfileCurrencyAlt(params) {
        return this._post(`user/currency_profile/delete_currency`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить валюту пользователя');
        });
    }

    static setProfileCurrencyDefault(params) {
        return this._post(`user/currency/set_default`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось установить по умолчанию валюту пользователя');
        });
    }

    static setProfileCurrencyDefaultAlt(params) {
        return this._post(`user/currency_profile/set_default`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось установить по умолчанию валюту пользователя');
        });
    }

    /* Tariffs, Billing, Subscription */
    static getBilling() {
        return this._get(`billing/tariffs`, {}).then((data) => {
            let billing = data;
            return billing;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список тарифов');
        });
    }
    static getBillingById(id) {
        return this._get(`billing/tariffs/${id}`, {}).then((data) => {
            let billing = data;
            return billing;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить тариф');
        });
    }
    static getBillingOrders() {
        return this._get(`billing/orders`, {}).then((data) => {
            let orders = data;
            return orders;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список платежей');
        });
    }
    static addBillingOrder(params) {
        return this._post(`billing/orders`, {}, params).then((data) => {
            let order = data;
            return order;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать платеж');
        });
    }
    static verifyBillingOrder(params) {
        return this._post(`billing/orders/verify`, {}, params).then((data) => {
            let order = data;
            return order;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить платеж');
        });
    }
    static cancelBillingSubscription(params) {
        return this._post(`billing/orders/deactivate_subscription`, {}, params).then((data) => {
            let order = data;
            return order;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отменить подписку на тариф');
        });
    }

    /* Reference books */
    static getCurrencies() {
        return this._get(`currency/currencies`, {}, true, false).then((data) => {
            let pages = data;
            return pages;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список валют');
        });
    }
    static getCurrency(id) {
        return this._get(`currency/currencies/${id}`, {}).then((data) => {
            let page = data;
            return page;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить валюту');
        });
    }
    static getInstrumentTypes() {
        return this._get(`reference_book/instruments_types`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список типов инструментов');
        });
    }
    static getOperations() {
        return this._get(`reference_book/operations`, {}).then((data) => {
            let pages = data;
            return pages;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список операций');
        });
    }
    static getOperation(id) {
        return this._get(`reference_book/operations/${id}`, {}).then((data) => {
            let page = data;
            return page;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить операцию');
        });
    }
    static getPages() {
        return this._get(`reference_book/pages`, {}, false, false).then((data) => {
            let pages = data;
            return pages;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить страницы');
        });
    }
    static getPage(slug) {
        return this._get(`reference_book/pages/${slug}`, {}, false, false).then((data) => {
            let page = data;
            return page;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить страницу');
        });
    }
    static getHints() {
        return this._get(`reference_book/hints`, {}, false, false).then((data) => {
            let hints = data;
            return hints;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить подсказки');
        });
    }
    static getHint(slug) {
        return this._get(`reference_book/hints/${slug}`, {}, true, false).then((data) => {
            let hint = data;
            return hint;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить подсказку');
        });
    }

    /* Brokers */
    /*
    static addBrokerAuth(params) {
        return this._post(`portfolio/broker_auth`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать токен брокера для портфеля');
        });
    }
    */
    static addBrokerAuth(params) {
        return this._post(`portfolio/token`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать токен брокера для портфеля');
        });
    }
    /*
    static updateBrokerAuth(params) {
        return this._put(`portfolio/broker_auth/${params.portfolio}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать токен брокера для портфеля');
        });
    }
    */
    static updateBrokerAuth(params) {
        return this._put(`portfolio/token/${params.portfolio}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить токен брокера для портфеля');
        });
    }

    static deleteBrokerAuth(params) {
        return this._delete(`portfolio/token/${params.portfolio}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить токен брокера для портфеля');
        });
    }

    static updateBrokerAuthDeals(params) {
        return this._post(`portfolio/token/${params.portfolio}/update_deals`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить сделки по токену для портфеля');
        });
    }

    static getBrokerList(params) {
        return this._get(`broker/brokers`, params, true, false).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка брокеров');
        });
    }

    static getBroker(id) {
        return this._get(`broker/brokers/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить брокера');
        });
    }

    static importBrokerData(params) {
        if (params.file) {
            let portfolio = params.portfolio_id;
            let id = params.file;
            return this._get(`portfolio/account/${portfolio}/reports/${id}/parse_report`, {}).then((data) => {
                return data;
            }).catch((error) => {
                throw new RESTError(error, 'Не удалось получить данные из файла брокера');
            });
        } else {
            /*
            return this._post(`broker/brokers/upload_portfolio_data`, {}, params).then((data) => {
                return data;
            }).catch((error) => {
                throw new RESTError(error, 'Не удалось получить данные от брокера');
            });
            */
            let portfolio = params.portfolio_id;
            return this._post(`portfolio/token/${portfolio}/update_deals`, {}, params).then((data) => {
                return data;
            }).catch((error) => {
                throw new RESTError(error, 'Не удалось получить данные от брокера');
            });
        }
    }







    /* Instrument */
    static getInstrumentList(params) {
        return this._get(`instrument/instrument`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить инструменты');
        });
    }

    static getInstrument(id) {
        return this._get(`instrument/instrument/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить инструмент');
        });
    }

    static getInstrumentHistoryDate(id, date) {
        return this._get(`instrument/instrument/${id}/history/${date}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить исторические данные инструмента');
        });
    }

    /* Bond */
    static getBondList(params) {
        return this._get(`instrument/bonds`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить облигации');
        });
    }

    static exportBondListXLS(params) {
        params.extraSettings = { responseType: 'arraybuffer' };
        return this._get(`instrument/bonds/export_list`, params, false, true, true).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось экспортировать облигации');
        });
    }

    static getBond(slug) {
        return this._get(`instrument/bonds/${slug}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить облигацию');
        });
    }

    static getBondCoupons(slug) {
        return this._get(`instrument/bonds/${slug}/coupons`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить купоны облигации');
        });
    }

    static getBondHistory(slug) {
        return this._get(`instrument/bonds/${slug}/history`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить истории облигации');
        });
    }

    static getBondCurrencies() {
        return this._get(`instrument/bonds/currencies`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список валют облигации');
        });
    }

    static getBondNominalFeatures() {
        return this._get(`instrument/bonds/nominal_features`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список особенностей номинала облигации');
        });
    }

    static getBondCouponFeatures() {
        return this._get(`instrument/bonds/coupon_features`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список особенностей купона облигации');
        });
    }

    static getBondTypes() {
        return this._get(`instrument/bonds/security_types`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список видов облигации');
        });
    }

    static getBondRatings() {
        return this._get(`instrument/bonds/rating_choices`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список рейтингов облигации');
        });
    }

    static getBondHasDefault() {
        return this._get(`instrument/bonds/has_default_choices`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список дефолтов облигации');
        });
    }

    /* Watchlist */
    static getWatchLists(params) {
        return this._get(`watch_list`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список WatchList');
        });
    }

    static addWatchList(params) {
        return this._post(`watch_list`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать WatchList');
        });
    }

    static updateWatchList(params) {
        let id = params.id;
        delete params.id;
        return this._patch(`watch_list/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить WatchList');
        });
    }

    static deleteWatchList(params) {
        let id = params.id;
        delete params.id;
        return this._delete(`watch_list/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить WatchList');
        });
    }

    static getWatchList(params) {
        let id = params.watchlistId;
        delete params.id;
        return this._get(`watch_list/${id}`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить WatchList');
        });
    }

    static addWatchListInstrument(params) {
        let id = params.id;
        delete params.id;
        return this._post(`watch_list/${id}/instruments`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать инструмент WatchList');
        });
    }

    static updateWatchListInstrument(params) {
        let id = params.id;
        delete params.id;
        let instrument = params.instrument;
        //delete params.instrument;
        return this._patch(`watch_list/${id}/instruments/${instrument}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить инструмент WatchList');
        });
    }

    static deleteWatchListInstrument(params) {
        return this._delete(`watch_list/${params.id}/instruments/${params.instrument}`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить инструмент WatchList');
        });
    }

    static getWatchListInstruments(params) {
        let id = params.id;
        delete params.id;
        return this._get(`watch_list/${id}/instruments`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить инструменты WatchList');
        });
    }

    static exportWatchListInstrumentsXLS(params) {
        let id = params.id;
        delete params.id;
        params.extraSettings = { responseType: 'arraybuffer' };
        return this._get(`watch_list/${id}/instruments/export`, params, false, true, true).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось экспортировать облигации');
        });
    }

    static getWatchListBranches(id) {
        return this._get(`watch_list/${id}/branches`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список отраслей WatchList');
        });
    }

    static getWatchListCurrencies(id) {
        return this._get(`watch_list/${id}/currencies`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список валют WatchList');
        });
    }

    static getWatchListEmitters(id) {
        return this._get(`watch_list/${id}/emitters`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список компаний WatchList');
        });
    }

    static getWatchListNominalFeatures(id) {
        return this._get(`watch_list/${id}/nominal_features`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список особенностей номинала WatchList');
        });
    }

    static getWatchListCouponFeatures(id) {
        return this._get(`watch_list/${id}/coupon_features`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список особенностей купона WatchList');
        });
    }

    /* Portfolio */
    static addPortfolioFile(id, params, portfolioType = 'account') {
        return this._post(`portfolio/${portfolioType}/${id}/upload_report`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось загрузить файл портфеля');
        });
    }

    static addPortfolio(params) {
        return this._post(`portfolio/account`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать портфель');
        });
    }

    static updatePortfolio(params, portfolioType = 'account') {
        let id = params.id;
        delete params.id;
        return this._patch(`portfolio/${portfolioType}/${id}`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить портфель');
        });
    }

    static deletePortfolio(params) {
        let id = params.id;
        delete params.id;
        return this._delete(`portfolio/account/${id}`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить портфель');
        });
    }

    static getPortfolioInstrumentList(params) {
        let id = params.id;
        delete params.id;
        return this._get(`portfolio/account/${id}/instruments`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список инструментов портфеля');
        });
    }

    static getPortfolioListSummary(params) {
        return this._get(`portfolio/account/summary`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить сводной информации по портфелям аккаунта');
        });
    }

    static getPortfolioList(params, portfolioType = 'account', use_cache = false, use_header = true) {
        return this._get(`portfolio/${portfolioType}`, params, use_cache, use_header).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка портфелей');
        });
    }

    static getPortfolioMenuList(params) {
        return this._get(`portfolio/account/menu_list`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка портфелей для меню');
        });
    }

    static getPortfolioProfileList(params) {
        return this._get(`portfolio/dashboard`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка портфелей для профиля');
        });
    }

    static getPortfolioDemoList(params) {
        return this._get(`portfolio/account/demo_list`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка демо портфелей');
        });
    }

    static getPortfolioDemoMenuList(params) {
        return this._get(`portfolio/account/demo_menu_list`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка демо портфелей для меню');
        });
    }

    static getPortfolioProfile(id, portfolioType = 'dashboard') {
        let type = (portfolioType === 'account') ? 'dashboard' : portfolioType;
        return this._get(`portfolio/${type}/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка портфелей для профиля');
        });
    }

    static getPortfolio(id, portfolioType = 'account', use_cache = false, use_header = true) {
        return this._get(`portfolio/${portfolioType}/${id}`, {}, use_cache, use_header).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить портфель');
        });
    }

    static getPortfolioChartsSummary() {
        return this._get(`portfolio/account/charts`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данных графиков портфеля');
        });
    }

    static getPortfolioChartsList(params, portfolioType = 'account') {
        let id = params.id;
        delete params.id;
        return this._get(`portfolio/${portfolioType}/${id}/charts`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данных графиков портфеля');
        });
    }

    static getPortfolioDealsList(id, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/deals`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка сделок');
        });
    }

    static getPortfolioAnalytics(id, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/analytics`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка сделок');
        });
    }
    /*
    static addPortfolioDeal(params) {
        return this._post(`portfolio/deals_create`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось содать сделку портфеля');
        });
    }
    */
    static addPortfolioDeal(params, portfolioType = 'account') {
        let id = params.portfolioId;
        delete params.portfolioId;
        return this._post(`portfolio/${portfolioType}/${id}/deals/add_deal`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось содать сделку с ЦБ портфеля');
        });
    }

    static updatePortfolioDeal(params, portfolioType = 'account') {
        let portfolioId = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._put(`portfolio/${portfolioType}/${portfolioId}/deals/${id}/update_deal`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить сделку с ЦБ портфеля');
        });
    }

    static undoPortfolioDeal(params, portfolioType = 'account') {
        let portfolio = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._post(`portfolio/${portfolioType}/${portfolio}/deals/${id}/undo_deal`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отменить редактирование сделки с ЦБ портфеля');
        });
    }

    static deletePortfolioDeal(params, portfolioType = 'account') {
        let portfolio = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._delete(`portfolio/${portfolioType}/${portfolio}/deals/${id}/delete_deal`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить сделку с ЦБ портфеля');
        });
    }

    static addPortfolioDealCash(params, portfolioType = 'account') {
        let id = params.portfolioId;
        delete params.portfolioId;
        return this._post(`portfolio/${portfolioType}/${id}/deals/add_cash`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось содать сделку c ДС портфеля');
        });
    }

    static updatePortfolioDealCash(params, portfolioType = 'account') {
        let portfolioId = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._put(`portfolio/${portfolioType}/${portfolioId}/deals/${id}/update_cash`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить сделку с ДС портфеля');
        });
    }

    static undoPortfolioDealCash(params, portfolioType = 'account') {
        let portfolio = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._post(`portfolio/${portfolioType}/${portfolio}/deals/${id}/undo_cash`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отменить редактирование сделки с ДС портфеля');
        });
    }

    static deletePortfolioDealCash(params, portfolioType = 'account') {
        let portfolio = params.portfolioId;
        let id = params.id;
        delete params.portfolioId;
        delete params.id;
        return this._delete(`portfolio/${portfolioType}/${portfolio}/deals/${id}/delete_cash`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить сделку с ДС портфеля');
        });
    }

    static getPortfolioDeals(id) {
        return this._get(`portfolio/deals/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка сделок портфеля');
        });
    }

    static getPortfolioDeal(params, portfolioType = 'account') {
        let portfolio = params.portfolioId;
        let id = params.dealId;
        return this._get(`portfolio/${portfolioType}/${portfolio}/deals/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка сделок портфеля');
        });
    }

    static getPortfolioAssetsList(id, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка активов портфеля');
        });
    }

    static getPortfolioAsset(id, ticker, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets/${ticker}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить информацияю по активу портфеля');
        });
    }

    static getPortfolioAssetChart(id, ticker, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets/${ticker}/chart`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить информацияю по событиям актива портфеля');
        });
    }

    static getPortfolioAssetHistory(id, ticker, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets/${ticker}/history`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить информацияю по историческим ценам актива портфеля');
        });
    }

    static getPortfolioAssetOperations(id, ticker, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets/${ticker}/operations`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить информацияю по истории сделок актива портфеля');
        });
    }

    static getPortfolioAssetSecurity(id, ticker, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/assets/${ticker}/security_payments`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить информацияю по истории дивидентов актива портфеля');
        });
    }

    static getPortfolioCashFlow(params, portfolioType = 'account') {
        let id = params.id;
        delete params.id;
        return this._get(`portfolio/${portfolioType}/${id}/cash_flow`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить ДДС портфеля');
        });
    }

    static getPortfolioCash(id, portfolioType = 'account') {
        return this._get(`portfolio/${portfolioType}/${id}/cash`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить средств кошелька портфеля');
        });
    }

    /* Trust */
    static getDashboardPortfolioList(params) {
        return this._get(`portfolio/dashboard`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка портфелей');
        });
    }
    static getTrustList(params) {
        return this._get(`portfolio/dashboard/trust_list`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка доверенных портфелей');
        });
    }

    static getTrust(id) {
        return this._get(`portfolio/trust/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить доверенный портфель');
        });
    }

    static getTrustInstrumentList(params) {
        let id = params.id;
        delete params.id;
        return this._get(`portfolio/trust/${id}/instruments`, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить список инструментов портфеля');
        });
    }

    static updateTrust(params) {
        return this._post(`portfolio/dashboard/trust_edit`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить доверенный портфель');
        });
    }

    static updateTrustInfo(params) {
        return this._post(`portfolio/dashboard/info_edit`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить публичную информацию портфеля');
        });
    }

    static deleteTrust(params) {
        return this._post(`portfolio/dashboard/trust_delete`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить доверенный портфель');
        });
    }

    static addTrustUser(params) {
        return this._post(`portfolio/dashboard/user_add`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось добавить управляющего для доверенного портфеля');
        });
    }

    static updateTrustUser(params) {
        return this._put(`portfolio/dashboard/user_edit`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось изменить управляющего для доверенного портфеля');
        });
    }

    static deleteTrustUser(params) {
        return this._put(`portfolio/dashboard/user_delete`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить управляющего для доверенного портфеля');
        });
    }

    static addPortfolioPublicLink(params) {
        return this._post(`portfolio/dashboard/public_url_add`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать публичную ссылку для портфеля');
        });
    }

    static deletePortfolioPublicLink(params) {
        return this._post(`portfolio/dashboard/public_url_delete`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить публичную ссылку для портфеля');
        });
    }

    // Старые функции для работы напрямут с АПИ Тинькофф
    static getPortfolioOld(params) {
        return this._post(`portfolio_old/operations/get_portfolio`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить портфеля по счету');
        });
    }

    static getEtfsOld(params) {
        return this._post(`portfolio_old/instruments/etfs`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка инвестицонных фондов');
        });
    }

    static getEtfByOld(params) {
        return this._post(`portfolio_old/instruments/etf_by`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данные инвестиционного фонда');
        });
    }

    static getSharesOld(params) {
        return this._post(`portfolio_old/instruments/shares`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка акций');
        });
    }

    static getShareByOld(params) {
        return this._post(`portfolio_old/instruments/share_by`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данные акции');
        });
    }

    static getFuturesOld(params) {
        return this._post(`portfolio_old/instruments/futures`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить списка фьючерсов');
        });
    }

    static getFutureByOld(params) {
        return this._post(`portfolio_old/instruments/future_by`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данные фьючерса');
        });
    }


    static getLastPricesOld(params) {
        return this._post(`portfolio_old/marketdata/get_last_prices`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данные акции');
        });
    }

    static getСandlesOld(params) {
        return this._post(`portfolio_old/marketdata/get_candles`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить данные акции');
        });
    }

}
