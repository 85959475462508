<template>
    <template v-if="!showLoaderSending">
        <div class="filters bond-list__filters">
            <div class="row mb-4">
                <div class="col-12 col-md-auto mb-3">
                    <div class="d-flex">
                        <div class="col me-2">
                            <b-card class="filters__item shadow">
                                <b-input-group>
                                    <b-form-input
                                        v-model="tableDataFilters.q"
                                        placeholder="Поиск по тикеру"
                                        size="sm"
                                        class="border-transparent py-0"
                                        @keyup.enter="changeFilterTableData"
                                    >
                                    </b-form-input>
                                    <b-input-group-append>
                                        <a
                                            v-if="tableDataFilters.q"
                                            href="#"
                                            class="btn-icon"
                                            @click.prevent="clearInputQ"
                                        >
                                            <b-icon-x-lg />
                                        </a>
                                        <div
                                            v-else
                                            class="btn-icon"
                                        >
                                            <b-icon-search />
                                        </div>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-card>
                        </div>
                        <b-button
                            variant="primary"
                            class="filters__item col-auto shadow m--mw-120"
                            size=""
                            pill
                            @click.prevent="changeFilterTableDataSimple"
                        >
                            Искать
                        </b-button>
                    </div>
                </div>
                <!--b-button
                    :variant="'light'"
                    class="filters__item col-auto me-3 mb-3 shadow"
                    size=""
                    pill
                    @click.prevent="toggleShowFilters"
                >
                    {{ showFilters ? 'Скрыть параметры поиска' : 'Показать параметры поиска' }}
                </b-button-->
                <div class="col-12 col-md-auto ms-auto mb-3">
                    <div class="d-flex">
                        <b-button
                            variant="primary"
                            class="filters__item col-auto ms-auto me-2"
                            size=""
                            pill
                            @click.prevent="exportWatchListInstrumentsXLS"
                        >
                            <template v-if="showLoaderExport">
                                <b-spinner
                                    class="me-3"
                                    small
                                />
                                <span>Генерация ...</span>
                            </template>
                            <template v-else>
                                Выгрузить в Excel
                            </template>
                        </b-button>
                        <BlockFilterTable
                            :tableHeader="tableHeader"
                            :tableHeaderName="tableHeaderName"
                            :tableFilters="tableFilters"
                            :minCols="2"
                            classButton="mb-0"
                            @changeFilterTable="changeFilterTable"
                        />
                    </div>
                </div>
            </div>
            <a ref="download" href="#" download />
        </div>
        <BlockFilterWatchlist
            v-if="showFilters"
            :watchlistId="watchlistId"
            :currentFilterKey="userSettings.filters?.watchList?.current"
            :tableDataFilters="tableDataFilters"
            :useFilters="useFilters"
            @changeFilterTableData="changeFilterTableData"
            @clearFilterTableData="clearFilterTableData"
        />
        <b-card
            :class="['bond-list__table col-12 shadow mb-5', (showLoaderSending && !currentTotalRows) && 'py-4']"
            bodyClass="p-1"
        >
            <template v-if="currentTotalRows">
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                    :items="prepareTableData()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header m--table-watchlist"
                    @row-selected="onRowSelected"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSorting"
                            @changeSorting="changeSorting"
                        />
                    </template>
                    <template #cell(action)="data">
                        <BlockActionsWatchlistInstrument
                            :key="`watchlist-instrument-${watchlistId}-${data.item.id}`"
                            :watchlistId="watchlistId"
                            :instrument="data.item"
                            @updateData="updateData"
                        />
                    </template>
                    <template #cell(name)="data">
                        <router-link
                            v-if="data.item.slug"
                            :to="{ name: 'bond', params: { slug: data.item.slug } }"
                            class="d-block"
                            target="_blank"
                        >
                            <strong>{{ data.item.name }}</strong>
                            <br>
                            <small class="text-muted">{{ data.item.ticker }}</small>
                        </router-link>
                        <template v-else>
                            <strong>{{ data.item.name }}</strong>
                            <br>
                            <small class="text-muted">{{ data.item.ticker }}</small>
                        </template>
                    </template>
                    <template #cell(price)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(quantity)="data">
                        <div class="d-flex m--input-control">
                            <b-form-input
                                id="input-quantity"
                                v-maska
                                data-maska="0"
                                data-maska-tokens="0:\d:multiple"
                                v-model="data.item.quantity"
                                type="text"
                                placeholder=""
                                size="sm"
                                :class="['shadow-sm py-0 px-2 m--no-pill', !data.item._editQuantity ? '' : 'disabled']"
                                :readonly="!data.item._editQuantity"
                                @click.prevent="toggleEditStatusQuantity(data.item)"
                                @keypress.enter="changeInstrumentQuantity(data.item)"
                                @blur="changeInstrumentQuantity(data.item)"
                            ></b-form-input>
                            <b-button
                                v-if="data.item._editQuantity"
                                variant="link-success"
                                class="py-0 px-1"
                                size="lg"
                                @click.prevent="changeInstrumentQuantity(data.item)"
                            >
                                <b-icon-check2 />
                            </b-button>
                            <b-button
                                variant="link-primary"
                                class="py-0 px-1"
                                size="lg"
                                @click.prevent="changeInstrumentCalculate(data.item)"
                            >
                                <template v-if="data.item.calculate">
                                    <b-icon-database-check />
                                </template>
                                <template v-else>
                                    <b-icon-database-x class="text-danger" />
                                </template>
                            </b-button>
                        </div>
                    </template>
                    <template #cell(share_in_list)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(share_year_income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(purchase_amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(aci_value)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(purchase_amount_aci)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(coupon_amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(coupon_amount_tax)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(year_income)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(close_income)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(total_income)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(maturity_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '-' }}
                    </template>
                    <template #cell(maturity_years)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(coupon_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                    </template>
                    <template #cell(coupon_frequency)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(amortization_date)="data">
                        {{ data.value ? $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') : '—' }}
                    </template>
                    <template #cell(amortization_amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(nominal_feature)="data">
                        <div class="small">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(coupon_feature)="data">
                        <div class="small">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(coupon_days_after)="data">
                        <div :class="['text-end', data.value <= 7 && 'text-success']">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(coupon_days_until_next)="data">
                        <div :class="['text-end', data.value <= 7 && 'text-danger']">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(emitter)="data">
                        <div class="small">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(emitter_inn)="data">
                        <div class="small">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(rating)="data">
                        {{ data.value ||  '—' }}
                    </template>
                    <template #cell(has_default)="data">
                        {{ data.value !== 'not' ? data.item.has_default_detail : '—' }}
                    </template>
                    <template #cell(purchase_date)="data">
                        <div class="small text-nowrap">
                            <b-button
                                variant="link-success"
                                class="opacity-50 py-0 px-2"
                                @click.prevent="showPurchaseWatchlistInstrument(data.item)"
                            >
                                <b-icon-pencil />
                            </b-button>
                            {{ data.item.purchase_date ? $helpers.formatDate(new Date(data.item.purchase_date), 'DD.MM.YYYY') : '' }}
                        </div>
                    </template>
                    <template #cell(portfolio_shares)="data">
                        <div v-if="!data.item.portfolio_shares.length">
                            —
                        </div>
                        <BlockPortfolioShares
                            v-else
                            :id="data.item.id"
                            :shares="data.item.portfolio_shares"
                            :sign="list.currency_symbol"
                        />
                    </template>
                    <template #cell(comment)="data">
                        <div class="small">
                            <b-button
                                variant="link-success"
                                class="opacity-50 py-0 px-2"
                                @click.prevent="showCommentWatchlistInstrument(data.item)"
                            >
                                <b-icon-pencil />
                            </b-button>
                            {{ data.item.comment }}
                        </div>
                    </template>
                </b-table>
            </template>
            <template v-else-if="!showLoaderSending">
                <b-alert
                    class="mb-0"
                    variant="warning"
                    :model-value="true"
                >
                    По вашему запросу ничего не найдено. Измените параметра поиска.
                </b-alert>
            </template>
            <div class="navigate m--border-bottom">
                <div class="navigate__filter py-1">
                    Показывать облигации:
                    <a
                        v-for="(item, index) in filterCoins"
                        :key="`coin-${index}`"
                        href="#"
                        :class="['navigate__filter-item px-1 ms-2', currentFilterCoins === item.value && 'active']"
                        @click.prevent="changeFilterCoins(item.value)"
                    >
                        <template v-if="item.icon === 'database-check'">
                            <b-icon-database-check />
                        </template>
                        <template v-if="item.icon === 'database-x'">
                            <b-icon-database-x class="text-danger" />
                        </template>
                        {{ item.title }}
                    </a>
                </div>
            </div>
            <BlockNavigateTable
                v-if="currentTotalRows"
                :totalRows="currentTotalRows"
                :currentPage="currentPage"
                :currentPerPage="currentPerPage"
                @changeNavigateTable="changeNavigateTable"
            />
            <b-overlay
                :show="showLoaderOverlay"
                variant="transparent"
                no-wrap
                spinner-variant="success"
            />
        </b-card>
        <ModalWatchlistInstrumentPurchase
            v-if="showModalPurchaseWatchlistInstrument"
            :watchlistId="watchlistId"
            :instrument="item"
            :show="showModalPurchaseWatchlistInstrument"
            @hideModal="hidePurchaseWatchlistInstrument"
        />
        <ModalWatchlistInstrumentComment
            v-if="showModalCommentWatchlistInstrument"
            :watchlistId="watchlistId"
            :instrument="item"
            :show="showModalCommentWatchlistInstrument"
            @hideModal="hideCommentWatchlistInstrument"
        />
    </template>
    <template v-else>
        <div class="app__loader">
            <b-spinner
                variant="success"
                class="me-3"
            />
            <span class="text-success">Загрузка данных ...</span>
        </div>
    </template>
</template>

<script>
    import { fieldNames, defaultWatchListFilter } from "@/settings/tables";
    import { app } from "@/services";

    import BlockActionsWatchlistInstrument from "@/components/block-actions-watchlist-instrument";
    import BlockFilterWatchlist from "@/components/block-filter-watchlist";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import BlockPortfolioShares from "@/components/block-portfolio-shares";
    import TableHeaderSlot from "@/components/table-header-slot";
    import ModalWatchlistInstrumentComment from "@/components/modals/modal-watchlist-instrument-comment";
    import ModalWatchlistInstrumentPurchase from "@/components/modals/modal-watchlist-instrument-purchase";

    export default {
        name: 'bondListCharts',
        emits: ['getWatchList'],
        components: {
            BlockActionsWatchlistInstrument,
            BlockFilterWatchlist,
            BlockFilterTable,
            BlockNavigateTable,
            BlockPortfolioShares,
            TableHeaderSlot,
            ModalWatchlistInstrumentComment,
            ModalWatchlistInstrumentPurchase,
        },
        props: {
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
            ticker: {
                type: String,
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                filterCoins: [{
                    title: 'Все',
                    value: null,
                }, {
                    title: 'Активные',
                    value: true,
                    icon: 'database-check'
                }, {
                    title: 'Исключённые',
                    value: false,
                    icon: 'database-x'
                }],
                currentFilterCoins: null,
                currentFilter: false,
                currentSorting: {
                    key: 'isin',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {},
                tableHeaderName: 'WatchlistItem',
                tableHeader: fieldNames.WatchlistItem,
                bonds: [],
                item: {},
                list: {},
                currentPerPage: 25,
                currentPage: 1,
                useFilters: false,
                showFilters: true,
                showLoaderExport: false,
                showLoaderOverlay: false,
                showLoaderSending: false,
                ModalPageSlug: null,
                showModalPage: false,
                showModalCommentWatchlistInstrument: false,
                showModalPurchaseWatchlistInstrument: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
            let settings = this.$store.state.user?.user_settings || {};
            let key = settings.filters?.watchList?.current;
            if (key) {
                this.tableDataFilters = Object.assign({}, settings.filters.watchList[key].filters);
            }
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1 || this.userSettings.tableHeader[this.tableHeaderName].indexOf(this.tableHeader[key].synonim) !== -1) this.tableFilters.push(key);
                } else {
                    if (!this.tableHeader[key].hideDefault) this.tableFilters.push(key);
                }
            });
            this.getWatchListInstruments();
        },
        methods: {
            getWatchListInstruments(simple = false, lazy = false) {
                lazy ? this.showLoaderOverlay = true : this.showLoaderSending = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    id: this.watchlistId,
                    calculate: this.currentFilterCoins,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                if (params.has_default_all) delete params.has_default;
                params.nominal_feature?.length ? params.nominal_feature = JSON.stringify(params.nominal_feature) : delete params.nominal_feature;
                params.coupon_feature?.length ? params.coupon_feature = JSON.stringify(params.coupon_feature) : delete params.coupon_feature;
                params.has_default?.length ? params.has_default = JSON.stringify(params.has_default) : delete params.has_default;
                params.rating?.length ? params.rating = JSON.stringify(params.rating) : delete params.rating;
                if (simple) {
                    params = {
                        id: this.watchlistId,
                        calculate: this.currentFilterCoins,
                        offset: (this.currentPage - 1) * this.currentPerPage,
                        limit: this.currentPerPage,
                        q: this.tableDataFilters.q,
                        ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                    };
                }
                if (params.currentFilterCoins === null) delete params.currentFilterCoins;
                app.getWatchListInstruments(params).then(res => {
                    console.log(res);
                    if (!res.error) {
                        this.currentTotalRows = res.count;
                        this.bonds = res.results;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderOverlay = false;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderOverlay = false;
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
                this.$emit('getWatchList', true, params);
            },
            prepareTableData() {
                let list = [...this.bonds];
                if (list.length) {
                    list.forEach((item, index) => {
                        item._rowVariant = item.coupon_days_until_next <= 90 ? 'text-danger' : '';
                        item._index = index + 1;
                    });
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                list = list.filter(item => {
                    if (this.tableDataFilters.isin) {
                        if (item.ticker_isin.toLowerCase().indexOf(this.tableDataFilters.ticker_isin.toLowerCase()) !== -1 || item.ticker_name.toLowerCase().indexOf(this.tableDataFilters.ticker_isin.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                return list;
            },
            changeFilterTableData(data = null) {
                console.log('changeFilterTableData');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.useFilters = true;
                this.getWatchListInstruments(false, true);
            },
            changeFilterTableDataSimple(data = null) {
                console.log('changeFilterTableDataSimple');
                if (data) this.tableDataFilters = Object.assign(this.tableDataFilters, data);
                this.currentPage = 1;
                this.getWatchListInstruments(true, true);
            },
            clearInputQ() {
                this.tableDataFilters.q = null;
            },
            clearFilterTableData() {
                this.tableDataFilters = Object.assign({}, defaultWatchListFilter);
                this.currentPage = 1;
                this.useFilters = false;
                this.getWatchListInstruments(false, true);
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
                this.getWatchListInstruments(false, true);
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
                this.getWatchListInstruments(false, true);
            },
            onRowSelected(item) {
                console.log(item);
            },
            toggleShowFilters() {
                this.showFilters = !this.showFilters;
            },
            changeMenuTab(name) {
                this.currentMenuTab = name;
            },
            changeFilterCoins(value) {
                this.currentFilterCoins = value;
                this.getWatchListInstruments(false, true);
            },
            toggleEditStatusQuantity(item) {
                this.item = item;
                this.item._editQuantity = this.item._editQuantity ? false : true;
            },
            changeInstrumentQuantity(item) {
                let params = {
                    id: this.watchlistId,
                    instrument: item.id,
                    quantity: item.quantity,
                    calculate: item.calculate
                };
                app.updateWatchListInstrument(params).then(res => {
                    if (!res.error) {
                        this.item._editQuantity = false;
                        this.getWatchListInstruments(false, true);
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                }).catch(err => {
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            changeInstrumentCalculate(item) {
                item.calculate = !item.calculate;
                this.changeInstrumentQuantity(item);
            },
            exportWatchListInstrumentsXLS() {
                this.showLoaderExport = true;
                let params = Object.assign({}, this.tableDataFilters, {
                    id: this.watchlistId,
                    offset: (this.currentPage - 1) * this.currentPerPage,
                    limit: this.currentPerPage,
                    ordering: `${this.currentSorting.direction === 'desc' ? '-' : ''}${this.currentSorting.key === 'valume_trading' ? 'value' : this.currentSorting.key}`
                });
                if (params.has_default_all) delete params.has_default;
                params.nominal_feature?.length ? params.nominal_feature = JSON.stringify(params.nominal_feature) : delete params.nominal_feature;
                params.coupon_feature?.length ? params.coupon_feature = JSON.stringify(params.coupon_feature) : delete params.coupon_feature;
                params.has_default?.length ? params.has_default = JSON.stringify(params.has_default) : delete params.has_default;
                params.rating?.length ? params.rating = JSON.stringify(params.rating) : delete params.rating;
                app.exportWatchListInstrumentsXLS(params).then(res => {
                    let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' });
                    this.$refs.download.href = window.URL.createObjectURL(blob);
                    this.$refs.download.download = decodeURI(res.headers['content-disposition'].split('filename=')[1]);
                    this.$refs.download.click();
                    this.showLoaderExport = false;
                }).catch(err => {
                    this.showLoaderExport = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            showPurchaseWatchlistInstrument(item) {
                this.item = item;
                this.showModalPurchaseWatchlistInstrument = true;
            },
            hidePurchaseWatchlistInstrument(refreshData = false) {
                this.showModalPurchaseWatchlistInstrument = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.getWatchListInstruments(false, true);
                    })
                }
            },
            showCommentWatchlistInstrument(item) {
                this.item = item;
                this.showModalCommentWatchlistInstrument = true;
            },
            hideCommentWatchlistInstrument(refreshData = false) {
                this.showModalCommentWatchlistInstrument = false;
                if (refreshData) {
                    this.$nextTick(() => {
                        this.getWatchListInstruments(false, true);
                    })
                }
            },
            updateData() {
                this.getWatchListInstruments(false, true);
            }
        }
    };
</script>
