const blocksNames = {
    // Портфель - Метрика
    accountInfo: {
        account_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_current_value: {
            name: 'Текущая стоимость брокерского счета',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_free_cash: {
            name: 'Свободные денежные средства',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_total_value: {
            name: 'Текущая суммарная стоимость ЦБ на БС',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_security_payments: {
            name: 'Доход 1. Дивиденды / купоны за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_total_profit: {
            name: 'Общий доход БС за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_amortization_sum: {
            name: 'Амортизация за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_info: {
            name: 'Общая информация по БС',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        account_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            //synonim: 'instrument_ticker',
            helper: '',
            hideDefault: true,
            //disabled: true,
        },
    },
    // Все портфели - Метрика
    accountsInfo: {
        accounts_invested: {
            name: 'Инвестировано',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_current_value: {
            name: 'Текущая стоимость всех брокерских счетов',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_free_cash: {
            name: 'Свободные денежные средства',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_total_value: {
            name: 'Текущая суммарная стоимость ЦБ на всех БС',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_security_payments: {
            name: 'Доход 1. Дивиденды / купоны за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_current_profit: {
            name: 'Доход 2. Прибыль по ЦБ текущая',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_security_payments_sum: {
            name: 'Доход 2. Прибыль по всем ЦБ за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_assets_total_profit: {
            name: 'Общий доход ЦБ за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_total_profit: {
            name: 'Общий доход БС за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_amortization_sum: {
            name: 'Амортизация за всю историю',
            //synonim: 'instrument_ticker',
            helper: '',
            //disabled: true,
        },
        accounts_assets_average_annual_income: {
            name: 'Среднегодовая прибыль ЦБ на БС',
            //synonim: 'instrument_ticker',
            helper: '',
            hideDefault: true,
            //disabled: true,
        },
    },
};

export {
    blocksNames,
};