<template>
    <b-modal
        id="modal-calendar-date"
        v-model="showModal"
        size="xl"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="new Date(events[0]?.start || null).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' })"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <b-table
                :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                :items="prepareTableData()"
                sticky-header
                responsive
                footClone
                hover
                class="table__data b-table-sticky-header m--table-flow"
            >
                <template #head()="data">
                    <TableHeaderSlot
                        :data="data"
                        :currentSorting="currentSorting"
                        @changeSorting="changeSorting"
                    />
                </template>
                <template #foot()="data">
                    <template
                        v-if="data.label.key === 'amount'"
                    >
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.label.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template
                        v-else
                    >
                        {{ data.label.value }}
                    </template>
                </template>
                <template #cell(title)="data">
                    <router-link
                        v-if="data.item.slug"
                        :to="$route.name === 'bond-screener-list' ? { name: 'bond', params: { slug: data.item.slug } } : { name: 'account-assets', params: { ticker: data.item.slug } }"
                        class="d-block"
                        @click="hideModal()"
                    >
                        <strong>{{ data.item.title }}</strong>
                        <br>
                        <small class="text-muted">{{ data.item.isin }}</small>
                    </router-link>
                    <template
                        v-else
                    >
                        <strong>{{ data.item.title }}</strong>
                        <br>
                        <small class="text-muted">{{ data.item.isin }}</small>
                    </template>
                </template>
                <template #cell(price)="data">
                    <div class="text-end text-nowrap">
                        {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                    </div>
                </template>
                <template #cell(quantity)="data">
                    <div class="text-end text-nowrap">
                        {{ data.value }}
                    </div>
                </template>
                <template #cell(rate)="data">
                    <div class="text-end text-nowrap">
                        {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                    </div>
                </template>
                <template #cell(record_date)="data">
                    {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}
                </template>
                <template #cell(payment_type)="data">
                    {{ data.value }}
                </template>
                <template #cell(amount)="data">
                    <div class="text-end text-nowrap">
                        {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                    </div>
                </template>
            </b-table>
            <BlockNavigateTable
                :totalRows="currentTotalRows"
                :currentPage="currentPage"
                :currentPerPage="currentPerPage"
                @changeNavigateTable="changeNavigateTable"
            />
        </div>
    </b-modal>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";

    export default {
        components: {
            BlockNavigateTable,
            TableHeaderSlot,
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.refreshData = false;
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            events: {
                type: Array,
                default() { return []; }
            },
            assetLink: {
                type: Boolean,
                default() { return false; }
            },
        },
        data() {
            return {
                currentSorting: {
                    key: 'name',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {},
                tableHeaderName: 'calendarItem',
                tableHeader: fieldNames.calendarItem,
                currentPerPage: 10,
                currentPage: 1,
                showModal: this.show,
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                this.tableFilters.push(key);
            });
        },
        methods: {
            prepareTableData() {
                let list = [...this.events];
                if (list.length) {
                    console.log('prepareTableData');
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? (a[this.currentSorting.key] || '') : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? (b[this.currentSorting.key] || '') : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                Object.keys(this.tableHeader).map((key) => {
                    if (this.tableHeader[key].total && key === 'amount') {
                        let value = 0;
                        list.map(item => {
                            value += item[key] * 1;
                        });
                        this.tableHeader[key].value = value;
                    }
                });
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            hideModal() {
                this.$emit('hideModal');
            },
        }
    };
</script>
