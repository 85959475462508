<template>
    <b-modal
        id="modal-calendar-event"
        v-model="showModal"
        size="md"
        classes="modal__container"
        header-class="pb-2 mb-2"
        body-class="pt-0"
        content-class="modal__block"
        :title="new Date(event.start).toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' })"
        centered
        hide-footer
        no-stacking
        @hidden="hideModal()"
    >
        <div class="modal__content">
            <div class="row">
                <div class="col-6 mb-3">
                    Купон на 1 шт
                    <div><strong>{{ event.price ? $helpers.toPrice(event.price, { pointer: ',' }) : '—' }}</strong></div>
                </div>
                <div class="col-6 mb-3">
                    Количество
                    <div><strong>{{ event.quantity || '—' }}</strong></div>
                </div>
            </div>
            <div class="event__amount h1 text-success text-center mb-4">
                <div class="mb-2">{{ event.amount > 0 ? '+' : '' }}{{ $helpers.toPrice(event.amount, { pointer: ',', sign: event.currency_symbol }) }}</div>
                <div>{{ event.payment_type }}</div>
            </div>
            <div class="row">
                <div class="col-6 mb-3">
                    Название
                    <div><strong>{{ event.title }}</strong></div>
                </div>
                <div class="col-6 mb-3">
                    ISIN
                    <div><strong>{{ event.isin }}</strong></div>
                </div>
                <div class="col-6 mb-3">
                    Ставка купона от номинала, %
                    <div><strong>{{ event.rate ? $helpers.toPrice(event.rate, { pointer: ',' }) : '—' }}</strong></div>
                </div>
                <div class="col-6 mb-3">
                    Закрытие реестра держателей
                    <div><strong>{{ $helpers.formatDate(new Date(event.record_date || null), 'DD.MM.YYYY') }}</strong></div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        components: {
        },
        watch: {
            show: {
                immediate: true,
                handler() {
                    this.refreshData = false;
                    this.showModal = this.show;
                }
            }
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
        },
        props: {
            show: {
                type: Boolean,
                default() { return false; }
            },
            event: {
                type: Object,
                default() { return {}; }
            },
        },
        data() {
            return {
                showModal: this.show,
            };
        },
        created() {
        },
        mounted() {
        },
        methods: {
            hideModal() {
                this.$emit('hideModal');
            },
        }
    };
</script>
