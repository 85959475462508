<template>
    <div class="account__flow">
        <template v-if="rules.account_flow && (rules.account_flow.limit === 0 || rules.account_flow.limit >= portfolio.ordering)">
            <div class="filters account__flow-filters form mb-4 d-flex flex-wrap">
                <b-form-group
                    id="input-group-period"
                    label="Период"
                    class="col m--date-range me-3"
                >
                    <b-input-group class="m--with-icon">
                        <template #append>
                            <b-icon-calendar2-check class="icon"></b-icon-calendar2-check>
                        </template>
                        <VueDatePicker
                            v-model="dateRange"
                            model-type="yyyy-MM-dd"
                            format="dd.MM.yyyy"
                            locale="ru"
                            select-text="Выбрать"
                            cancel-text="Отменить"
                            range
                            :auto-apply="true"
                            :close-on-auto-apply="false"
                            :year-range="[2000, 2050]"
                            :min-date="portfolio.first_deal_date || new Date()"
                            :max-date="new Date()"
                            :prevent-min-max-navigation="true"
                            text-input
                            :enable-time-picker="false"
                            :month-change-on-scroll="true"
                            :clearable="false"
                            :hide-input-icon="true"
                        >
                            <template #dp-input="{ value, onInput, onEnter, onTab }">
                                <input
                                    v-maska
                                    data-maska="##.##.####  -  ##.##.####"
                                    data-maska-eager
                                    class="form-control border-transparent shadow m--no-pill"
                                    type="text"
                                    :value="value"
                                    @keyup="onInput"
                                    @keydown.enter="onEnter"
                                    @keydown.tab="onTab"
                                />
                            </template>
                        </VueDatePicker>
                    </b-input-group>
                </b-form-group>
                <div class="mb-3 me-3 mt-auto me-auto">
                    <b-card class="filters__item shadow">
                        <b-form-checkbox variant="success" v-model="tableDataFilters.hide_empty" name="check-button" switch>
                            Скрыть периоды без операций
                        </b-form-checkbox>
                    </b-card>
                </div>
            </div>
            <div class="account__filters d-flex mb-4">
                <div class="d-flex flex-wrap">
                    <b-button
                        v-for="item in dateFilters"
                        :key="item.name"
                        :variant="(item.name === currentFilter) ? 'success' : 'light'"
                        class="text-nowrap me-3 mb-2 shadow"
                        :class="(item.name === currentFilter) ? 'active' : ''"
                        pill
                        @click.stop="changeFilter(item.name)"
                    >
                        {{ item.title }}
                    </b-button>
                </div>
                <BlockFilterTable
                    :tableHeader="tableHeader"
                    :accountId="portfolioId"
                    :tableHeaderName="tableHeaderName"
                    :tableHeaderGroups="tableHeaderGroups"
                    :tableFilters="tableFilters"
                    @changeFilterTable="changeFilterTable"
                />
            </div>
            <b-card
                class="account__flow-table col-12 shadow mb-5"
                bodyClass="p-1"
            >
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                    :items="prepareTableData()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header m--table-flow"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSorting"
                            @changeSorting="changeSorting"
                        />
                    </template>
                    <template #cell(date)="data">
                        <div class="small text-nowrap">
                            {{ data.item.date_detail }}
                        </div>
                    </template>
                    <template #cell(own_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(invested_cash)="data">
                        <div class="text-end text-nowrap">
                            <router-link
                                :to="{ name: 'account-history', params: { dataFiltersProps: 'currency' } }"
                            >
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </router-link>
                        </div>
                    </template>
                    <template #cell(withdrawn_cash)="data">
                        <div class="text-end text-nowrap">
                            <router-link
                                :to="{ name: 'account-history', params: { dataFiltersProps: 'currency' } }"
                            >
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </router-link>
                        </div>
                    </template>
                    <template #cell(purchased_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(sold_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(tax_commission_withheld)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(tax_commission_refund)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(open_balance)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(close_balance)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(purchased_assets)="data">
                        <div class="text-end text-nowrap">
                            <router-link
                                :to="{ name: 'account-history', params: { dataFiltersProps: 'share' } }"
                            >
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </router-link>
                        </div>
                    </template>
                    <template #cell(sold_assets)="data">
                        <div class="text-end text-nowrap">
                            <router-link
                                :to="{ name: 'account-history', params: { dataFiltersProps: 'share' } }"
                            >
                                {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                            </router-link>
                        </div>
                    </template>
                    <template #cell(security_payment_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(open_assets_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(close_assets_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(asset_income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(asset_income_percent)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income_percent)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                </b-table>
                <BlockNavigateTable
                    :totalRows="currentTotalRows"
                    :currentPage="currentPage"
                    :currentPerPage="currentPerPage"
                    @changeNavigateTable="changeNavigateTable"
                />
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                    z-index="1010"
                />
            </b-card>
            <h2 class="mb-4">Итог</h2>
            <b-card
                class="account__flow-table col-12 shadow mb-5"
                bodyClass="p-1"
            >
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeaderTotal, this.tableFiltersTotal)"
                    :items="prepareTableDataTotal()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header m--table-flow"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSortingTotal"
                            @changeSorting="changeSortingTotal"
                        />
                    </template>
                    <!--template #thead-sub="data">
                        <div class="table__data-head-value">{{ data.label ? data.label.value : '' }}</div>
                    </template-->
                    <template #cell(own_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(invested_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(withdrawn_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(purchased_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(sold_cash)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(tax_commission_withheld)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(tax_commission_refund)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(open_balance)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(close_balance)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(purchased_assets)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(sold_assets)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(security_payment_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(open_assets_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(close_assets_sum)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(asset_income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(asset_income_percent)="data">
                        <div class="text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                    <template #cell(income_percent)="data">
                        <div class="text-end text-nowrap">
                            {{ $helpers.toPrice(data.value, { pointer: ',' }) }}
                        </div>
                    </template>
                </b-table>
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                    z-index="1010"
                />
            </b-card>
        </template>
        <template v-else>
            <BlockTariffLimited />
        </template>
    </div>
</template>

<script>
    import { fieldNames } from "@/settings/tables";

    import { app } from "@/services";
    import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import BlockTariffLimited from "@/components/block-tariff-limited";
    import TableHeaderSlot from "@/components/table-header-slot";
    export default {
        name: 'accountFlow',
        components: {
            BlockFilterTable,
            BlockNavigateTable,
            BlockTariffLimited,
            TableHeaderSlot,
        },
        props: {
            portfolioId: {
                type: [Number, String],
                default() { return null; }
            },
            portfolio: {
                type: Object,
                default() { return {}; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        watch: {
            dateRange: {
                immediate: true,
                handler() {
                    if (this.dateRange[0] && this.dateRange[1]) {
                        console.log('dateRange', this.dateRange);
                        this.$nextTick(() => {
                            this.getPortfolioCashFlow();
                        });
                    }
                }
            },
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent.value;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        data() {
            return {
                dateRange: [],
                dateFilters: [{
                    name: 'year',
                    title: 'По годам'
                }, {
                    name: 'month',
                    title: 'По месяцам'
                }, {
                    name: 'week',
                    title: 'По неделям'
                }, {
                    name: 'day',
                    title: 'По дням'
                }],
                tableFilters: [],
                tableDataFilters: {
                    hide_empty: false,
                },
                tableFiltersTotal: [],
                tableHeaderName: 'flowItem',
                tableHeader: fieldNames.flowItem,
                tableHeaderTotal: fieldNames.flowTotalItem,
                tableHeaderGroups: fieldNames.flowItemGroups,
                currentFilter: 'day',
                currentSorting: {
                    key: 'date',
                    direction: 'desc'
                },
                currentSortingTotal: {
                    key: 'currency',
                    direction: 'asc'
                },
                //fields: [],
                //fieldsTotal: [],
                flow: [],
                flowTotal: [],
                currentPerPage: 50,
                currentPage: 1,
                showLoaderSending: false,
            };
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.$breadcrumbs.value.at(-3).label = (this.portfolioType === 'account') ? this.portfolio.name : (this.portfolio.trust_name || this.portfolio.name);
            //const now = new Date();
            //const firstDay = new Date(now.getFullYear() - 1, now.getMonth(), 1);
            const firstDay = new Date(this.portfolio.first_deal_date || new Date());
            //const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            const lastDay = new Date();
            //this.dateRange = {
            //    start: this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
            //    end:  this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
            //};
            this.dateRange = [
                this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
            ];

            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                if (this.userSettings.accounts?.[this.portfolioId]?.[this.tableHeaderName]) {
                    if (this.userSettings.accounts[this.portfolioId][this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else if (this.userSettings.tableHeader?.[this.tableHeaderName]) {
                    if (this.userSettings.tableHeader[this.tableHeaderName].indexOf(key) !== -1) this.tableFilters.push(key);
                } else {
                    this.tableFilters.push(key);
                }
            });
            this.tableFiltersTotal = [];
            Object.keys(this.tableHeaderTotal).map((key) => {
                this.tableFiltersTotal.push(key);
            });
            //this.getPortfolioCashFlow();
        },
        methods: {
            getPortfolioCashFlow() {
                this.showLoaderSending = true;
                let params = {
                    id: this.portfolioId,
                    date_from: this.dateRange[0],
                    date_to: this.dateRange[1],
                    period: this.currentFilter,
                };
                app.getPortfolioCashFlow(params, this.portfolioType).then(res => {
                    if (!res.error) {
                        this.flow = res.history;
                        this.flowTotal = res.total;
                        this.currentPage = 1;
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                });
            },
            prepareTableData() {
                let list = [...this.flow];
                if (list.length) {
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            prepareTableDataTotal() {
                let list = [...this.flowTotal];
                if (list.length) {
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSortingTotal.key) {
                        list = list.sort((a, b) => {
                            if (a[this.currentSortingTotal.key] > b[this.currentSortingTotal.key])
                                return this.currentSortingTotal.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSortingTotal.key] < b[this.currentSortingTotal.key])
                                return this.currentSortingTotal.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                /*
                list = list.filter(item => {
                    //if (this.currentDataFilter === 'all') {
                    //    return true;
                    //}
                    //console.log(new Date(item.date), new Date(this.dateRange.start), new Date(this.dateRange.end));
                    if (new Date(item.date) >= new Date(this.dateRange.start) && new Date(item.date) <= new Date(this.dateRange.end)) {
                        return true;
                    }
                    return false;
                });
                */
                /* Фильтр - Показать периоды без операций */
                list = list.filter(item => {
                    if (this.tableDataFilters.hide_empty) {
                        return item.has_deals;
                    }
                    return true;
                });
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeSortingTotal(sorting) {
                console.log(sorting);
                this.currentSortingTotal = sorting;
            },
            changeFilter(name) {
                this.currentFilter = name;
                this.getPortfolioCashFlow();
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeFilterTableTotal(filter) {
                this.tableFiltersTotal = filter;
            },
            changeNavigateTable(page, perPage) {
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            setDataRange() {
                const now = new Date();
                const firstDay = this.portfolio.first_deal_date;
                const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                //this.dateRange = {
                //    start: this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                //    end:  this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
                //};
                this.dateRange = [
                    this.$helpers.formatDate(firstDay, 'YYYY-MM-DD'),
                    this.$helpers.formatDate(lastDay, 'YYYY-MM-DD'),
                ];
            }
        }
    };
</script>
