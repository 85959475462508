<template>
    <div
        :class="[classMain, classModifier]"
    >
        <b-card
            :class="['shadow h-100', reverse ? 'flex-column-reverse' : '']"
            :bodyClass="`${classMain}-inner`"
        >
            <div :class="['content pe-4 pb-3 mb-auto m--title', classTitle]">
                {{ title }}
            </div>
            <template v-if="showFull && values">
                <div class="row">
                    <div :class="['d-flex', `${classMain}-title`, classColor, classColorValue]">
                        <div>
                            <template v-if="valueLabel">
                                <span class="text-muted mb-1 small">{{ valueLabel }}</span><br>
                            </template>
                            <div
                                v-for="currency in currencyList"
                                :key="`currency-item-${currency.name}`"
                                :class="[classColor ? values[currency.iso_name] >= 0 ? 'text-success' : 'text-danger' : '']"
                            >
                                <span :class="['h5 mb-0', classColor, classColorValue]">
                                    {{ showValues(currency) }}
                                </span>
                            </div>
                        </div>
                        <div class="ms-auto ps-2">
                            <template v-if="subvalueLabel">
                                <span class="text-muted mb-1 small">{{ subvalueLabel }}</span><br>
                            </template>
                            <div
                                v-for="currency in currencyList"
                                :key="`currency-item-${currency.name}`"
                                :class="[classColor ? values[currency.iso_name] >= 0 ? 'text-success' : 'text-danger' : '']"
                            >
                                <span
                                    v-if="subvalues"
                                    :class="['h5 mb-0', classColor, classColorSubvalue]"
                                >
                                    {{ showSubValues(currency) }}
                                </span>
                                <span
                                    v-else-if="subvalue && index === 0"
                                    :class="['h5 mb-0', classColor, classColorSubvalue]"
                                >
                                    {{ showSubValue() }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row">
                    <div :class="['d-flex', `${classMain}-title`, classColor]">
                        <div>
                            <template v-if="valueLabel">
                                <span class="text-muted mb-1 small">{{ valueLabel }}</span><br>
                            </template>
                            <span :class="['h5 mb-0', classColor, classColorValue]">
                                {{ showValue() }}
                                <template v-if="valueHelper">
                                    <sup :id="`popover-block-${valueHelper.key}`" class="ms-1">
                                        <b-icon-info-circle></b-icon-info-circle>
                                    </sup>
                                    <b-popover
                                        custom-class="shadow"
                                        :target="`popover-block-${valueHelper.key}`"
                                        triggers="hover"
                                        placement="top"
                                    >
                                        <div
                                            v-html="valueHelper.content"
                                        />
                                    </b-popover>
                                </template>
                            </span>
                            <template v-if="valueSublabel">
                                <br><span class="text-muted mb-1 small">{{ valueSublabel }}</span>
                            </template>
                        </div>
                        <div
                            v-if="subvalue !== null"
                            class="ms-auto ps-2"
                        >
                            <template v-if="subvalueLabel">
                                <span class="text-muted mb-1 small">{{ subvalueLabel }}</span><br>
                            </template>
                            <span :class="['h5 mb-0', classColor, classColorSubvalue]">{{ showSubValue() }}</span>
                            <template v-if="subvalueSublabel">
                                <br><span class="text-muted mb-1 small">{{ subvalueSublabel }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="helper">
                <Helper
                    :id="id"
                    :helper="helper"
                    :classParent="classMain"
                />
            </template>
        </b-card>
    </div>
</template>

<script>
    import Helper from "@/components/helper";
    export default {
        components: {
            Helper
        },
        props: {
            title: {
                type: String,
                default() { return ''; }
            },
            value: {
                type: [Number, String],
                default() { return ''; }
            },
            valueLabel: {
                type: String,
                default() { return ''; }
            },
            valueSublabel: {
                type: String,
                default() { return ''; }
            },
            valueHelper: {
                type: Object,
                default() { return null; }
            },
            values: {
                type: Object,
                default() { return null; }
            },
            valueToPrice: {
                type: Boolean,
                default() { return false; }
            },
            valueToPercent: {
                type: Boolean,
                default() { return false; }
            },
            valueUseSign: {
                type: Boolean,
                default() { return false; }
            },
            valueCurrency: {
                type: String,
                default() { return null; }
            },
            valueUseCurrency: {
                type: [Object, String],
                default() { return null; }
            },
            subvalue: {
                type: [Number, String],
                default() { return ''; }
            },
            subvalueLabel: {
                type: String,
                default() { return ''; }
            },
            subvalueSublabel: {
                type: String,
                default() { return ''; }
            },
            subvalues: {
                type: Object,
                default() { return null; }
            },
            subvalueToPrice: {
                type: Boolean,
                default() { return false; }
            },
            subvalueToPercent: {
                type: Boolean,
                default() { return false; }
            },
            subvalueUseSign: {
                type: Boolean,
                default() { return false; }
            },
            showFull: {
                type: Boolean,
                default() { return false; }
            },
            reverse: {
                type: Boolean,
                default() { return false; }
            },
            classMain: {
                type: String,
                default() { return ''; }
            },
            classModifier: {
                type: String,
                default() { return 'col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 mb-4'; }
            },
            classTitle: {
                type: String,
                default() { return ''; }
            },
            classColor: {
                type: String,
                default() { return ''; }
            },
            classColorValue: {
                type: String,
                default() { return ''; }
            },
            classColorSubvalue: {
                type: String,
                default() { return ''; }
            },
            helper: {
                type: String,
                default() { return null; }
            }
        },
        computed: {
            currencyCurrent() {
                if (this.valueUseCurrency) {
                    return this.valueUseCurrency;
                } else {
                    return this.currencyList.filter(item => item.iso_name === this.$store.state.currencyCurrent.iso_name)[0];
                }
            },
        },
        data() {
            return {
                id: Math.floor(Math.random() * 100000),
                currencyList: [...this.$store.state.currencyList],
            }
        },
        mounted() {
            this.currencyList = this.currencyList.sort((a, b) => {
                if (a.default && !b.default)
                    return -1;
                if (!a.default && b.default)
                    return 1;
                return 0;
            });
        },
        methods: {
            showValue() {
                if (this.valueToPrice) {
                    let val = this.$helpers.toPrice(this.value, { sign: this.currencyCurrent.symbol, pointer: ',' });
                    if (this.valueUseSign) {
                        return (this.value > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else if (this.valueToPercent) {
                    let val = this.$helpers.toPrice(this.value, { sign: '%', pointer: ',' });
                    if (this.valueUseSign) {
                        return (this.value > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else {
                    if (this.valueUseSign) {
                        return (this.value > 0) ? `+${this.value}` : this.value;
                    } else {
                        return this.value;
                    }
                }
            },
            showValues(currency) {
                if (this.valueToPrice) {
                    let val = this.$helpers.toPrice(this.values[currency.iso_name] || 0, { sign: currency.symbol, pointer: ',' });
                    if (this.valueUseSign) {
                        return (this.values[currency.iso_name] > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else if (this.valueToPercent) {
                    let val = this.$helpers.toPrice(this.values[currency.iso_name] || 0, { sign: '%', pointer: ',' });
                    if (this.valueUseSign) {
                        return (this.values[currency.iso_name] > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else {
                    if (this.valueUseSign) {
                        return (this.values[currency.iso_name] > 0) ? `+${this.values[currency.iso_name]}` : this.values[currency.iso_name];
                    } else {
                        return this.values[currency.iso_name];
                    }
                }
            },
            showSubValue() {
                if (this.subvalueToPrice) {
                    let val = this.$helpers.toPrice(this.subvalue, { sign: this.currencyCurrent.symbol, pointer: ',' });
                    if (this.subvalueUseSign) {
                        return (this.subvalue > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else if (this.subvalueToPercent) {
                    let val = this.$helpers.toPrice(this.subvalue, { sign: '%', pointer: ',' });
                    if (this.subvalueUseSign) {
                        return (this.subvalue > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else {
                    if (this.subvalueUseSign) {
                        return (this.subvalue > 0) ? `+${this.subvalue}` : this.subvalue;
                    } else {
                        return this.subvalue;
                    }
                }
            },
            showSubValues(currency) {
                if (this.subvalueToPrice) {
                    let val = this.$helpers.toPrice(this.subvalues[currency.iso_name] || 0, { sign: currency.symbol, pointer: ',' });
                    if (this.subvalueUseSign) {
                        return (this.subvalues[currency.iso_name] > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else if (this.subvalueToPercent) {
                    let val = this.$helpers.toPrice(this.subvalues[currency.iso_name] || 0, { sign: '%', pointer: ',' });
                    if (this.subvalueUseSign) {
                        return (this.subvalues[currency.iso_name] > 0) ? `+${val}` : val;
                    } else {
                        return val;
                    }
                } else {
                    if (this.subvalueUseSign) {
                        return (this.subvalues[currency.iso_name] > 0) ? `+${this.subvalues[currency.iso_name]}` : this.subvalues[currency.iso_name];
                    } else {
                        return this.subvalues[currency.iso_name];
                    }
                }
            }
        }
    };
</script>
