<template>
    <template v-if="rules.watchlist_coupons">
        <template v-if="!showLoaderSending">
            <h2 class="mb-4">
                График выплаты купонов и амортизации
                <Helper
                    id="popover-chart-payout-watchlist"
                    helper="watchlist_payout_chart"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <b-card class="shadow mb-5 py-4">
                <highcharts
                    :id="`chart-coupons-${watchlistId}`"
                    :options="chartOptions || {}"
                >
                </highcharts>
            </b-card>
            <div class="bond-list__buttons row mb-3">
                <div class="col-12 col-xl d-flex flex-wrap mb-2">
                    <template
                        v-for="item in chartTabs"
                        :key="item.name"
                    >
                        <b-button
                            v-if="item.show"
                            :variant="(item.name === currentChartTab) ? 'outline-primary' : 'outline-primary'"
                            :active="item.name === currentChartTab"
                            :disabled="item.disabled"
                            class="bond-list__buttons-item me-3 mb-2"
                            size=""
                            pill
                            @click.stop="changeChartTab(item.name)"
                        >
                            {{ item.title }}
                        </b-button>
                    </template>
                </div>
            </div>

            <b-card class="bond-list__table col-12 shadow mb-5">
                <b-table-simple
                    hover
                    responsive
                >
                    <b-thead>
                        <b-tr>
                            <b-td class="border-bottom">Месяц</b-td>
                            <template
                                v-for="(item, index) in list.coupon_month_income"
                                :key="`th-${index}`"
                            >
                                <b-td class="border-bottom text-end text-nowrap">
                                    {{ new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' }) }}<br>
                                    {{ new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                                </b-td>
                            </template>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="currentChartTab === 'income'">
                            <b-tr>
                                <b-td>Купон</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-coupon_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.coupon_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td>АД, погашение</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-amortization_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.amortization_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td class="border-bottom">
                                    Итого
                                    <Helper
                                        id="popover-watchlist_total_amount"
                                        helper="watchlist_total_amount"
                                        classModifier="m--dark"
                                    />
                                </b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-total_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap border-bottom">
                                        {{ $helpers.toPrice(item.total_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                        </template>
                        <template v-else-if="currentChartTab === 'income-13'">
                            <b-tr>
                                <b-td>Купон</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-resident_tax_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.coupon_amount - item.resident_tax_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td>АД, погашение</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-amortization_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.amortization_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td class="border-bottom">
                                    Итого
                                    <Helper
                                        id="popover-chart-watchlist_resident_tax_amount"
                                        helper="watchlist_resident_tax_amount"
                                        classModifier="m--dark"
                                    />
                                </b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-total_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap border-bottom">
                                        {{ $helpers.toPrice(item.total_amount - item.resident_tax_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                        </template>
                        <template v-else-if="currentChartTab === 'income-30'">
                            <b-tr>
                                <b-td>Купон</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-non_resident_tax_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.coupon_amount - item.non_resident_tax_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td>АД, погашение</b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-amortization_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap">
                                        {{ $helpers.toPrice(item.amortization_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                            <b-tr>
                                <b-td class="border-bottom">
                                    Итого
                                    <Helper
                                        id="popover-chart-watchlist_non_resident_tax_amount"
                                        helper="watchlist_non_resident_tax_amount"
                                        classModifier="m--dark"
                                    />
                                </b-td>
                                <template
                                    v-for="(item, index) in list.coupon_month_income"
                                    :key="`td-total_amount-${index}`"
                                >
                                    <b-td class="text-end text-nowrap border-bottom">
                                        {{ $helpers.toPrice(item.total_amount - item.non_resident_tax_amount, { pointer: ',' }) }}
                                    </b-td>
                                </template>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </b-card>

            <h2 class="mb-4">Календарь выплаты купонов</h2>
            <b-card class="account__coupons-block-calendar col-12 shadow mb-5">
                <ScheduleXCalendar
                    v-if="this.calendarApp"
                    :calendar-app="calendarApp"
                    class="sx"
                >
                    <template #monthGridEvent="{ calendarEvent }">
                        <div
                            class="sx__month-grid-event-custom"
                            :style="`border-left: 4px solid var(--sx-color-${calendarEvent.calendarId}); color: var(--sx-color-on-${calendarEvent.calendarId}-container); background-color: var(--sx-color-${calendarEvent.calendarId}-container);`"
                        >
                            <div class="sx__month-grid-event-custom-amount">
                                {{ calendarEvent.amount > 0 ? '+' : '' }}{{ $helpers.toPrice(calendarEvent.amount, { pointer: ',', sign: calendarEvent.currency_symbol }) }}
                            </div>
                            <div class="sx__month-grid-event-custom-title">
                                {{ calendarEvent.title }}
                                <span>{{ calendarEvent.payment_type }}</span>
                            </div>
                        </div>
                    </template>
                    <template #monthAgendaEvent="{ calendarEvent }">
                        <div
                            class="sx__month-agenda-event-custom"
                            :style="`border-left: 4px solid var(--sx-color-${calendarEvent.calendarId}); color: var(--sx-color-on-${calendarEvent.calendarId}-container); background-color: var(--sx-color-${calendarEvent.calendarId}-container);`"
                        >
                            <div class="sx__month-agenda-event-custom-amount">
                                {{ calendarEvent.amount > 0 ? '+' : '' }}{{ $helpers.toPrice(calendarEvent.amount, { pointer: ',', sign: calendarEvent.currency_symbol }) }}
                            </div>
                            <div class="sx__month-agenda-event-custom-title">
                                {{ calendarEvent.title }}
                                <span>{{ calendarEvent.payment_type }}</span>
                            </div>
                        </div>
                    </template>
                </ScheduleXCalendar>
            </b-card>

            <h2 class="mb-4">
                Детализация купонных выплат и амортизаций
                <Helper
                    id="popover-account-coupons-payout-detail"
                    helper="account_coupons_payout_detail"
                    classModifier="m--dark"
                    sup
                />
            </h2>
            <div class="account__coupons-block-buttons row mb-3">
                <div class="col-12 col-xl d-flex flex-wrap mb-2">
                    <b-dropdown id="dropdown-onpage" variant="outline-primary" size="" class="navigate__select-button shadow">
                        <template #button-content>
                            {{ new Date(list.coupon_month_income?.[currentFilter].date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(list.coupon_month_income?.[currentFilter]?.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                        </template>
                        <b-dropdown-item-button
                            v-for="(item, index) in list.coupon_month_income"
                            :key="`filter-month-${item.date}`"
                            buttonClass="navigate__select-item"
                            :active="currentFilter === index ? true : false"
                            @click="changeFilter(index)"
                        >
                            {{ new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' }) }} {{ new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' }) }}
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </div>
            <b-card
                class="account__coupons-block-table col-12 shadow mb-5"
                bodyClass="p-1"
            >
                <b-table
                    :fields="$helpers.prepareTableFields(this.tableHeader, this.tableFilters)"
                    :items="prepareTableData()"
                    sticky-header
                    responsive
                    hover
                    class="table__data b-table-sticky-header m--table-flow"
                >
                    <template #head()="data">
                        <TableHeaderSlot
                            :data="data"
                            :currentSorting="currentSorting"
                            @changeSorting="changeSorting"
                        />
                    </template>
                    <template #cell(name)="data">
                        <router-link
                            v-if="data.item.slug"
                            :to="{ name: 'bond', params: { slug: data.item.slug } }"
                            class="d-block"
                            target="_blank"
                        >
                            <strong>{{ data.value }}</strong>
                            <br>
                            <small class="text-muted">{{ data.item.ticker }}</small>
                        </router-link>
                        <template
                            v-else
                        >
                            <strong>{{ data.item.name }}</strong>
                            <br>
                            <small class="text-muted">{{ data.value }}</small>
                        </template>
                    </template>
                    <template #cell(coupon_rate)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(payment_date)="data">
                        {{ $helpers.formatDate(new Date(data.value), 'DD.MM.YYYY') }}
                    </template>
                    <template #cell(payment_type_detail)="data">
                        {{ data.value }}
                    </template>
                    <template #cell(amount)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                    <template #cell(year_income)="data">
                        <div class="text-end text-nowrap">
                            {{ data.value ? $helpers.toPrice(data.value, { pointer: ',' }) : '—' }}
                        </div>
                    </template>
                </b-table>
                <BlockNavigateTable
                    :totalRows="currentTotalRows"
                    :currentPage="currentPage"
                    :currentPerPage="currentPerPage"
                    @changeNavigateTable="changeNavigateTable"
                />
                <b-overlay
                    :show="showLoaderSending"
                    variant="transparent"
                    no-wrap
                    spinner-variant="success"
                />
            </b-card>
            <ModalCalandarEvent
                :event="currentEvent"
                :show="showModalCalandarEvent"
                @hideModal="hideCalendarEvent"
            />
            <ModalCalandarDate
                :events="currentDateEvents"
                :show="showModalCalandarDate"
                @hideModal="hideCalendarDate"
            />
        </template>
        <template v-else>
            <div class="app__loader">
                <b-spinner
                    variant="success"
                    class="me-3"
                />
                <span class="text-success">Загрузка данных ...</span>
            </div>
        </template>
    </template>
    <template v-else>
        <BlockTariffLimited />
    </template>
</template>

<script>
    import { fieldNames } from "@/settings/tables";
    import { accountCoupon } from "@/settings/charts";

    import { app } from "@/services";
    //import BlockFilterTable from "@/components/block-filter-table";
    import BlockNavigateTable from "@/components/block-navigate-table";
    import TableHeaderSlot from "@/components/table-header-slot";
    import Helper from "@/components/helper";
    import ModalCalandarEvent from "@/components/modals/modal-calendar-event";
    import ModalCalandarDate from "@/components/modals/modal-calendar-date";
    import { ScheduleXCalendar } from '@schedule-x/vue';
    import { createCalendar, viewMonthGrid, viewMonthAgenda } from '@schedule-x/calendar';
    import '@schedule-x/theme-default/dist/index.css';
    import BlockTariffLimited from "@/components/block-tariff-limited";

    export default {
        name: 'bondListCharts',
        components: {
            //BlockFilterTable,
            BlockNavigateTable,
            TableHeaderSlot,
            Helper,
            ModalCalandarEvent,
            ModalCalandarDate,
            ScheduleXCalendar,
            BlockTariffLimited
        },
        props: {
            watchlistId: {
                type: [Number, String],
                default() { return null; }
            },
            ticker: {
                type: String,
                default() { return null; }
            },
            portfolioType: {
                type: String,
                default() { return 'account'; }
            },
        },
        data() {
            return {
                chartTabs: [{
                    name: 'income',
                    title: 'Без налогов',
                    icon: null,
                    show: true
                }, {
                    name: 'income-13',
                    title: 'С учетом налога 13%',
                    icon: null,
                    show: true
                }, {
                    name: 'income-30',
                    title: 'С учетом налога 30%',
                    icon: null,
                    show: true
                }],
                currentChartTab: 'income',
                currentFilter: 0,
                currentSorting: {
                    key: 'payment_date',
                    direction: 'asc'
                },
                tableFilters: [],
                tableDataFilters: {},
                tableHeaderName: 'couponItem',
                tableHeader: fieldNames.couponItem,
                bonds: [],
                item: {},
                list: {},
                events: [],
                currentEvent: {},
                currentDateEvents: [],
                calendarDateMin: null,
                calendarDateMax: null,
                currentPerPage: 10,
                currentPage: 1,
                calendarApp: null,
                chartOptions: {},
                showModalCalandarEvent: false,
                showModalCalandarDate: false,
                showLoaderSending: false,
            };
        },
        computed: {
            rules() {
                return this.$store.state.user?.tariff?.rules || {};
            },
            currencyCurrent() {
                return this.$store.state.currencyCurrent;
            },
            userSettings() {
                return this.$store.state.user?.user_settings || {};
            },
        },
        created() {
            // Определяем не реактивную переменную компонента для отключения реактивности, дабы не вызывался повторно методом prepareTableData() при присвоении значения this.currentTotalRows = 0;
            Object.defineProperty(this, 'currentTotalRows', {
                configurable: false,
                value: 0
            })
        },
        mounted() {
            this.tableFilters = [];
            Object.keys(this.tableHeader).map((key) => {
                this.tableFilters.push(key);
            });
            this.getWatchList();
        },
        methods: {
            getWatchList(lazy = false, params = {}) {
                this.chartOptions = Object.assign({}, accountCoupon);
                if (!lazy) this.showLoaderSending = true;
                params.watchlistId = this.watchlistId;
                delete params.ordering;
                app.getWatchList(params).then(res => {
                    if (!res.error) {
                        this.list = res;
                        this.bonds = this.list.coupon_month_income[this.currentFilter].month_instruments;
                        this.chartOptions.tooltip.valueSuffix = ` ${this.currencyCurrent.symbol}`;
                        this.chartOptions.yAxis.labels.format = this.currencyCurrent.symbol + ' {text}';
                        this.chartOptions.series[0].name = this.chartOptions.series[0].nameTemplate + this.currencyCurrent.symbol;
                        this.chartOptions.series[1].name = this.chartOptions.series[1].nameTemplate + this.currencyCurrent.symbol;

                        this.chartOptions.series[0].data = [];
                        this.chartOptions.series[1].data = [];

                        this.list.coupon_month_income.forEach(item => {
                            const date = `${new Date(item.date).toLocaleDateString('ru-RU', { month: 'long' })} ${new Date(item.date).toLocaleDateString('ru-RU', { year: 'numeric' })}`;
                            this.chartOptions.series[0].data.push([
                                date,
                                item.amortization_amount,
                            ]);
                            this.chartOptions.series[1].data.push([
                                date,
                                item.coupon_amount,
                            ]);
                            this.chartOptions.xAxis.categories.push(date);
                        });
                        this.list.coupon_month_income.forEach(item => {
                            Object.keys(item.month_payment_dates).map((key) => {
                                item.month_payment_dates[key].forEach((event, index) => {
                                    this.events.push({
                                        id: `${key}-${index}`,
                                        calendarId: event.payment_type,
                                        title: event.name,
                                        isin: event.isin,
                                        slug: event.slug,
                                        rate: event.coupon_rate,
                                        start: key,
                                        end: key,
                                        record_date: event.record_date,
                                        amount: event.amount,
                                        price: event.price,
                                        quantity: event.quantity,
                                        payment_type: event.payment_type_detail,
                                        currency_symbol: event.currency_symbol
                                    });
                                });
                            });
                        })
                        this.calendarDateMin = this.list.coupon_month_income.at(0).date;
                        this.calendarDateMax = this.list.coupon_month_income.at(-1).date;
                        this.generateCalendar();
                    } else {
                        this.$store.dispatch('showError', {err: { message: res.error }});
                    }
                    this.$breadcrumbs.value.at(-1).label = this.list.name;
                    this.showLoaderSending = false;
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                    console.error(err);
                });
            },
            prepareTableData() {
                let list = [...this.bonds];
                if (list.length) {
                    console.log('prepareTableData');
                    list = this.filterTableData(list);
                    this.currentTotalRows = list.length;
                    if (this.currentSorting.key) {
                        list = list.sort((a, b) => {
                            a[this.currentSorting.key] = isNaN(a[this.currentSorting.key]) ? a[this.currentSorting.key] : +a[this.currentSorting.key];
                            b[this.currentSorting.key] = isNaN(b[this.currentSorting.key]) ? b[this.currentSorting.key] : +b[this.currentSorting.key];
                            if (a[this.currentSorting.key] > b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? 1 : -1;
                            if (a[this.currentSorting.key] < b[this.currentSorting.key])
                                return this.currentSorting.direction === 'asc' ? -1 : 1;
                            return 0;
                        });
                    }
                    if (this.currentPerPage) {
                        list = list.splice((this.currentPage - 1) * this.currentPerPage, this.currentPerPage);
                    }
                }
                console.log(list);
                return list;
            },
            filterTableData(data) {
                let list = [...data];
                /*
                list = list.filter(item => {
                    item._rowVariant = item.sold ? 'secondary text-muted' : '';
                    if (this.tableDataFilters.instrument_ticker) {
                        if (item.instrument_ticker.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1 || item.instrument_name.toLowerCase().indexOf(this.tableDataFilters.instrument_ticker.toLowerCase()) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                });
                list = list.filter(item => {
                    if (this.tableDataFilters.sold) {
                        return true;
                    } else {
                        return (item.sold) ? false : true;
                    }
                });
                */
                return list;
            },
            changeSorting(sorting) {
                this.currentSorting = sorting;
            },
            changeFilterTable(filter) {
                this.tableFilters = filter;
            },
            changeNavigateTable(page, perPage) {
                console.log(page, perPage);
                this.currentPage = page;
                this.currentPerPage = perPage;
            },
            changeFilter(index) {
                console.log(index);
                this.currentFilter = index;
                this.bonds = this.list.coupon_month_income[this.currentFilter].month_instruments;
            },
            rowClass(item, type) {
                console.log(item);
                if (!item || type !== 'row') return;
                if (item.slod) return 'table-success';
            },
            onRowSelected(item) {
                console.log(item);
            },
            changeChartTab(name) {
                this.currentChartTab = name;
            },
            async generateCalendar() {
                /*
                this.calendarControls = createCalendarControlsPlugin();
                const scrollController = createScrollControllerPlugin({
                    initialScroll: '09:50'
                });
                const eventModal = createEventModalPlugin();
                const datePicker = createDatePicker({
                    listeners: {
                        onChange: (date) => {
                            console.log('date changed', date)
                        }
                    },
                });
                */
                let self = this;
                this.calendarApp = await createCalendar({
                    calendars: {
                        coupon: {
                            colorName: 'coupon',
                            lightColors: {
                                main: 'rgba(20, 108, 67, 1)',
                                container: 'rgba(20, 108, 67, 0.2)',
                                onContainer: 'rgba(20, 108, 67, 1)',
                            },
                            darkColors: {
                                main: '#fff5c0',
                                onContainer: '#fff5de',
                                container: '#a29742',
                            },
                        },
                        amortization: {
                            colorName: 'amortization',
                            lightColors: {
                                main: 'rgb(125, 125, 125, 1)',
                                container: 'rgb(205, 205, 205, 0.2)',
                                onContainer: 'rgb(125, 125, 125, 1)',
                            },
                            darkColors: {
                                main: '#ffc0cc',
                                onContainer: '#ffdee6',
                                container: '#a24258',
                            },
                        },
                    },
                    //calendars: this.eventTypes,
                    locale: 'ru-RU',
                    //selectedDate: new Date(),
                    minDate: this.calendarDateMin,
                    maxDate: this.calendarDateMax,
                    monthGridOptions: {
                        nEventsPerDay: 2
                    },
                    views: [viewMonthGrid, viewMonthAgenda],
                    defaultView: viewMonthGrid.name,
                    events: this.events || [],
                    //plugins: [this.calendarControls, scrollController, eventModal],
                    //plugins: [this.calendarControls, scrollController],
                    /**
                    * Callbacks for events that occur in the calendar
                    * */
                    callbacks: {
                        /**
                         * Is called when:
                         * 1. Selecting a date in the date picker
                         * 2. Selecting a new view
                         * */
                        onRangeUpdate(range) {
                            console.log('new calendar range start date', range.start)
                            console.log('new calendar range end date', range.end)
                        },
                        /**
                         * Is called when an event is updated through drag and drop
                         * */
                        onEventUpdate(updatedEvent) {
                            console.log('onEventUpdate', updatedEvent)
                        },
                        /**
                        * Is called when an event is clicked
                        * */
                        onEventClick(calendarEvent) {
                            console.log('onEventClick', calendarEvent)
                            self.currentEvent = calendarEvent;
                            self.showCalendarEvent();
                        },
                        /**
                        * Is called when clicking a date in the month grid
                        * */
                        onClickDate(date) {
                            console.log('onClickDate', date) // e.g. 2024-01-01
                            self.currentDateEvents = self.events.filter(item => item.start === date);
                            console.log(self.currentDateEvents);
                            if (self.currentDateEvents.length) self.showCalendarDate();
                        },
                        /**
                        * Is called when clicking somewhere in the time grid of a week or day view
                        * */
                        onClickDateTime(dateTime) {
                            console.log('onClickDateTime', dateTime) // e.g. 2024-01-01 12:37
                        },
                        /**
                        * Is called when clicking the "+ N events" button of a month grid-day
                        * */
                        onClickPlusEvents(date) {
                            console.log('onClickPlusEvents', date) // e.g. 2024-01-01
                        },
                        /**
                        * Is called when the selected date is updated
                        * */
                        onSelectedDateUpdate(date) {
                            console.log('onSelectedDateUpdate', date)
                        }
                    },

                });
            },
            showCalendarEvent() {
                this.showModalCalandarEvent = true;
            },
            hideCalendarEvent() {
                this.showModalCalandarEvent = false;
            },
            showCalendarDate() {
                this.showModalCalandarDate = true;
            },
            hideCalendarDate() {
                this.showModalCalandarDate = false;
            },
        }
    };
</script>
