const fieldNames = {
    // Портфель - Активы
    activeItem: {
        instrument_name: {
            name: 'Наименование / Тикер',
            synonim: 'instrument_ticker',
            helper: '',
            sort: true,
            disabled: true,
        },
        instrument_exchange_name: {
            name: 'Биржа',
            helper: '',
            sort: true
        },
        country: {
            name: 'Страна',
            helper: '',
            sort: true
        },
        quantity: {
            name: 'Количество',
            helper: '',
            sort: true
        },
        share_in_portfolio: {
            name: 'Доля в портфеле, %',
            helper: '',
            sort: true
        },
        average_annual_income: {
            name: 'Среднегодовая прибыль по активу',
            helper: 'active_average_annual_income',
            sort: true
        },
        average_price: {
            name: 'Средняя цена',
            helper: '',
            sort: true
        },
        price: {
            name: 'Текущая цена, шт.',
            helper: '',
            sort: true
        },
        aci: {
            name: 'Текущий НКД, шт.',
            helper: '',
            sort: true
        },
        actual_sum_aci: {
            name: 'Текущая цена с НКД, сумма',
            helper: '',
            sort: true
        },
        currency: {
            name: 'Валюта актива',
            helper: '',
            sort: true
        },
        actual_sum: {
            name: 'Текущая цена, сумма',
            helper: '',
            sort: true
        },
        payment_sum: {
            name: 'Сумма покупок',
            helper: '',
            sort: true
        },
        income: {
            name: 'Доход 2. П/У в валюте актива',
            helper: 'active_income',
            sort: true
        },
        income_percent: {
            name: 'Доход 2. П/У, %',
            helper: 'active_income_percent',
            sort: true
        },
        income_aci: {
            name: 'Доход 2 с НКД. П/У в валюте актива с НКД',
            helper: 'active_income_aci',
            sort: true
        },
        income_aci_percent: {
            name: 'Доход 2 с НКД. П/У с НКД, %',
            helper: 'active_income_aci_percent',
            sort: true
        },
    },
    // Портфель - Денежные средства
    cashItem: {
        currency: {
            name: 'Валюта',
            helper: '',
            sort: true,
            value: 'Итого'
        },
        amount: {
            name: 'Баланс (сумма на счету)',
            helper: '',
            sort: true,
            total: true
        },
        amount_user_currency: {
            name: 'Стоимость в валюте клиента',
            nameSuffix: 'currencyIsoSign',
            helper: '',
            sort: true,
            total: true
        },
    },
    // Портфель - ДДС
    flowItem: {
        date: {
            name: 'Период',
            helper: '',
            sort: true
        },
        own_cash: {
            name: 'Собственные средства (накопительным итогом)',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        invested_cash: {
            name: 'Всего зачислено средств',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        withdrawn_cash: {
            name: 'Всего выведено средств',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        purchased_cash: {
            name: 'Куплено валюты на сумму',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        sold_cash: {
            name: 'Продано валюты на сумму',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        tax_commission_withheld: {
            name: 'Удержано комиссий, налогов и прочие расходы',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        tax_commission_refund: {
            name: 'Возврат комиссий, налогов и прочие доходы',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        open_balance: {
            name: 'Остаток средств на начало периода',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        close_balance: {
            name: 'Остаток средств на конец периода',
            nameSuffix: 'currencySign',
            group: 'funds',
            helper: '',
            sort: true
        },
        purchased_assets: {
            name: 'Куплено активов на сумму',
            nameSuffix: 'currencySign',
            group: 'assets',
            helper: '',
            sort: true
        },
        sold_assets: {
            name: 'Продано активов + АД на сумму',
            nameSuffix: 'currencySign',
            group: 'assets',
            helper: '',
            sort: true
        },
        security_payment_sum: {
            name: 'Доход 1. Всего получено дивидендов / купонов',
            nameSuffix: 'currencySign',
            group: 'assets',
            helper: '',
            sort: true
        },
        open_assets_sum: {
            name: 'Оценка (стоимость) активов на начало периода',
            nameSuffix: 'currencySign',
            group: 'assets',
            helper: '',
            sort: true
        },
        close_assets_sum: {
            name: 'Оценка (стоимость) активов на конец периода',
            nameSuffix: 'currencySign',
            group: 'assets',
            helper: '',
            sort: true
        },
        asset_income: {
            name: 'Доход 2. П/У активов за период',
            nameSuffix: 'currencySign',
            group: 'pl',
            helper: '',
            sort: true
        },
        /*
        asset_income_percent: {
            name: 'Доход 2. П/У активов за период, %',
            group: 'pl',
            helper: '',
            sort: true
        },
        */
        income: {
            name: 'Общий доход. П/У брокерского счета за период',
            nameSuffix: 'currencySign',
            group: 'pl',
            helper: '',
            sort: true
        },
        /*
        income_percent: {
            name: 'Общий доход. П/У брокерского счета за период, %',
            group: 'pl',
            helper: '',
            sort: true
        },
        */
    },
    // Портфель - ДДС - группировка
    flowItemGroups: [{
        title: null,
        name: null,
    }, {
        title: 'Денежные средства',
        name: 'funds',
        class: 'col-12',
    }, {
        title: 'Активы',
        name: 'assets',
        class: 'col-12',
    }, {
        title: 'Прибыль/Убытки',
        name: 'pl',
        class: 'col-12',
    }],
    // Портфель - ДДС - Итог
    flowTotalItem: {
        currency: {
            name: 'Валюта',
            helper: '',
            sort: true
        },
        own_cash: {
            name: 'Собственные средства (накопительным итогом)',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        invested_cash: {
            name: 'Всего зачислено средств',
            nameSuffix: 'currencySign',
            helper: '',
        },
        withdrawn_cash: {
            name: 'Всего выведено средств',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        purchased_cash: {
            name: 'Куплено валюты на сумму',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        sold_cash: {
            name: 'Продано валюты на сумму',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        tax_commission_withheld: {
            name: 'Удержано комиссий, налогов и прочие расходы',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        tax_commission_refund: {
            name: 'Возврат комиссий, налогов и прочие доходы',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        open_balance: {
            name: 'Остаток средств на начало периода',
            nameSuffix: 'currencySign',
            helper: ''
        },
        close_balance: {
            name: 'Остаток средств на конец периода',
            nameSuffix: 'currencySign',
            helper: ''
        },
        purchased_assets: {
            name: 'Куплено активов на сумму',
            nameSuffix: 'currencySign',
            helper: ''
        },
        sold_assets: {
            name: 'Продано активов на сумму',
            nameSuffix: 'currencySign',
            helper: ''
        },
        security_payment_sum: {
            name: 'Всего получено дивидендов/ купонов',
            nameSuffix: 'currencySign',
            helper: ''
        },
        open_assets_sum: {
            name: 'Оценка (стоимость) активов на начало периода',
            nameSuffix: 'currencySign',
            helper: ''
        },
        close_assets_sum: {
            name: 'Оценка (стоимость) активов на конец периода',
            nameSuffix: 'currencySign',
            helper: ''
        },
        asset_income: {
            name: 'П/У активов за период',
            nameSuffix: 'currencySign',
            helper: ''
        },
        /*
        asset_income_percent: {
            name: 'П/У активов за период, %',
            helper: ''
        },
        */
        income: {
            name: 'П/У брокерского счета за период',
            nameSuffix: 'currencySign',
            helper: ''
        },
        /*
        income_percent: {
            name: 'П/У брокерского счета за период, %',
            helper: ''
        },
        */
    },
    // Портфель - История сделок
    historyItem: {
        date: {
            name: 'Дата',
            helper: '',
            sort: true,
            disabled: true
        },
        /*
        figi: {
            name: 'Figi-идентификатора инструмента',
            helper: '',
            sort: true
        },
        */
        ticker_isin: {
            name: 'Тикер / ISIN',
            helper: '',
            join: ['instrument_ticker', 'instrument_isin'],
            disabled: true
        },
        instrument_name: {
            name: 'Название',
            helper: '',
            sort: true
        },
        instrument_type: {
            name: 'Тип',
            helper: ''
        },
        operation_type: {
            name: 'Операция',
            helper: ''
        },
        currency: {
            name: 'Валюта',
            helper: ''
        },
        quantity: {
            name: 'Кол-во',
            helper: 'account_history_quantity',
            sort: true
        },
        price: {
            name: 'Цена, 1 шт.',
            helper: '',
            sort: true
        },
        /*
        price_commission: {
            name: 'Цена с комиссией, 1 шт.',
            helper: '',
            sort: true
        },
        */
        aci: {
            name: 'НКД',
            helper: '',
            sort: true
        },
        payment_sum: {
            name: 'Сумма сделки',
            helper: '',
            sort: true
        },
        commission: {
            name: 'Комиссия',
            helper: ''
        },
        payment_sum_commission: {
            name: 'Сумма сделки с комиссией',
            helper: '',
            sort: true
        },
        comment: {
            name: 'Комментарий',
            helper: ''
        },
        action: {
            //icon: 'action',
            //helper: 'account_history_menu',
        }
    },
    // Портфель - История сделок - Конфигуратор фильтров
    historyConfig: [{
        title: 'Акции',
        name: 'share',
        class: 'col-12',
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sale',
            class: 'col-6',
        }, {
            title: 'Ввод актива',
            name: 'asset_receive',
            class: 'col-6',
        }, {
            title: 'Вывод актива',
            name: 'asset_withdrawal',
            class: 'col-6',
        }, {
            title: 'Дивиденды',
            name: 'dividend',
            class: 'col-6',
        }, {
            title: 'Выплата в виде акций*',
            name: 'payout',
            class: 'col-6',
        }]
    }, {
        title: 'Облигация',
        name: 'bond',
        class: 'col-12',
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sale',
            class: 'col-6',
        }, {
            title: 'Ввод актива',
            name: 'asset_receive',
            class: 'col-6',
        }, {
            title: 'Вывод актива',
            name: 'asset_withdrawal',
            class: 'col-6',
        }, {
            title: 'Погашение',
            name: 'repayment',
            class: 'col-6',
        }, {
            title: 'Амортизация',
            name: 'amortization',
            class: 'col-6',
        }, {
            title: 'Купонный доход',
            name: 'coupon',
            class: 'col-6',
        }]
    }, {
        title: 'Денежные средства',
        name: 'currency',
        class: 'col-12',
        items: [{
            title: 'Внесение на счет',
            name: 'deposit',
            class: 'col-6',
        }, {
            title: 'Вывод со счета',
            name: 'withdrawal',
            class: 'col-6',
        }, {
            title: 'Покупка валюты',
            name: 'buy',
            alias: ['currency_buy'],
            class: 'col-6',
        }, {
            title: 'Продажа валюты',
            name: 'sale',
            alias: ['currency_sale'],
            class: 'col-6',
        }, {
            title: 'Кредит*',
            name: 'credit',
            class: 'col-6',
        }]
    }, {
        title: 'Налог',
        name: 'tax',
        class: 'col-12',
        items: [{
            title: 'Удержание',
            name: 'withholding',
            class: 'col-6',
        }, {
            title: 'Возмещение',
            name: 'refund',
            class: 'col-6',
        }]
    }, {
        title: 'Комиссия',
        name: 'commission',
        class: 'col-12',
        items: [{
            title: 'Удержание',
            name: 'commission',
            class: 'col-6',
        }, {
            title: 'Возмещение',
            name: 'commission_refund',
            class: 'col-6',
        }]
    }, {
        title: 'Прочее',
        name: 'other',
        class: 'col-12',
        items: [{
            title: 'Прочий доход',
            name: 'other_income',
            class: 'col-6',
        }, {
            title: 'Прочий расход',
            name: 'other_expense',
            class: 'col-6',
        }, {
            title: 'Перевод актива с другого БС*',
            name: 'transfer',
            class: 'col-6',
        }]
    }, {
        title: 'ETF / ПИФ',
        name: 'etf',
        class: 'col-12',
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sell',
            class: 'col-6',
        }, {
            title: 'Дивиденды',
            name: 'dividend',
            class: 'col-6',
        }, {
            title: 'Выплата в виде акций',
            name: 'payout',
            class: 'col-6',
        }]
    }, {
        title: 'IPO*',
        name: 'ipo',
        class: 'col-12',
        disabled: true,
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sell',
            class: 'col-6',
        }]
    }, {
        title: 'Фьючерс*',
        name: 'futures',
        class: 'col-12',
        disabled: true,
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sale',
            class: 'col-6',
        }]
    }, {
        title: 'Опцион*',
        name: 'fund',
        class: 'col-12',
        disabled: true,
        items: [{
            title: 'Покупка',
            name: 'buy',
            class: 'col-6',
        }, {
            title: 'Продажа',
            name: 'sale',
            class: 'col-6',
        }]
    }],
    // Портфель - Купонные выплаты и амортизация
    couponItem: {
        name: {
            name: 'Наименование / Тикер',
            helper: '',
            sort: true
        },
        payment_date: {
            name: 'Дата выплаты',
            helper: 'account_coupon_item_payment_date',
            sort: true
        },
        amount: {
            name: 'Сумма выплат',
            helper: 'account_coupon_item_amount',
            sort: true
        },
        payment_type_detail: {
            name: 'Тип выплаты',
            helper: '',
            sort: true
        },
        year_income: {
            name: 'Доходность 1, %',
            helper: 'account_coupon_year_income',
            sort: true
        },
        coupon_rate: {
            name: 'Ставка купона от номинала, % годовых',
            helper: '',
            sort: true
        },
    },

    // Портфель - Купонные выплаты и амортизация. Календарь
    calendarItem: {
        title: {
            name: 'Наименование / Тикер',
            helper: '',
            sort: true,
            value: 'Итого'
        },
        price: {
            name: 'Купон на 1 шт',
            helper: '',
            sort: true
        },
        quantity: {
            name: 'Количество, шт',
            helper: '',
            sort: true
        },
        amount: {
            name: 'Сумма выплат',
            helper: '',
            sort: true,
            total: true
        },
        payment_type: {
            name: 'Тип выплаты',
            helper: '',
            sort: true
        },
        rate: {
            name: 'Ставка купона от номинала, %',
            helper: '',
            sort: true
        },
        record_date: {
            name: 'Закрытие реестра держателей',
            helper: '',
            sort: true
        },
    },
    // Актив - История сделок по активу
    assetTransactionItem: {
        purchase_date: {
            name: 'Дата покупки',
            helper: '',
            sort: true
        },
        purchase_price: {
            name: 'Цена покупки',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        sale_date: {
            name: 'Дата продажи',
            helper: '',
            sort: true
        },
        sale_price: {
            name: 'Цена продажи (погашение)',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        quantity: {
            name: 'Количество, шт.',
            helper: '',
            sort: true
        },
        price: {
            name: 'Текущая цена, 1 лот',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        /*
        commission: {
            name: 'Комиссия',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        income: {
            name: 'Доход 2. П/У',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        /*
        income_user_currency: {
            name: 'Доход 2. П/У',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        income_percent: {
            name: 'Доход 2. П/У, %',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        /*
        income_percent_user_currency: {
            name: 'Доход 2. П/У, %',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        /*
        security_payment_sum: {
            name: 'Доход 1. Сумма полученных дивов или купонов с даты покупки',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        /*
        income_security_payment: {
            name: 'Общий доход. П/У с учетом дивов или купонов',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        /*
        income_security_payment_user_currency: {
            name: 'Общий доход. П/У с учетом дивов или купонов',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        /*
        income_percent_security_payment: {
            name: 'Общий доход. П/У с учетом дивов или купонов, %',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        /*
        income_percent_security_payment_user_currency: {
            name: 'Общий доход. П/У с учетом дивов или купонов, %',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        days_long: {
            name: 'Кол-во дней в работе',
            helper: ''
        },
        years_long: {
            name: 'Кол-во лет в работе',
            helper: ''
        },
        /*
        year_income: {
            name: 'Расчетная ср. прибыль в год, % с даты покупки',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        /*
        year_income_user_currency: {
            name: 'Расчетная ср. прибыль в год, % с даты покупки',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        /*
        pu_last_year: {
            name: 'Доход 2. П/У за последние 12 мес., %',
            nameSuffix: 'currencyAssetSign',
            helper: '',
            sort: true
        },
        */
        /*
        pu_last_year_user: {
            name: 'Доход 2. П/У за последние 12 мес., %',
            nameSuffix: 'currencySign',
            helper: '',
            sort: true
        },
        */
        sold: {
            name: 'Продан?',
            helper: ''
        },
        /*
        tax: {
            name: 'Расчетный налог',
            helper: ''
        },
        */
        /*
        comment: {
            name: 'Комментарий',
            helper: ''
        }
        */
    },
    // Актив - История дивидентов по активу
    assetDividentItem: {
        date: {
            name: 'Дата',
            helper: '',
            sort: true
        },
        /*
        assets_nums: {
            name: 'Количество',
            helper: '',
            sort: true
        },
        */
        currency_iso_name: {
            name: 'Валюта',
            helper: '',
            sort: true
        },
        payment: {
            name: 'Сумма выплат',
            helper: '',
            sort: true
        },
        /*
        amount_declared: {
            name: 'Сумма объявленных дивов на одну акцию (до выплаты налогов), ₽',
            helper: '',
            sort: true
        },
        amount_received: {
            name: 'Сумма, полученная на счет за одну акцию с учетом налогов, ₽',
            helper: '',
            sort: true
        },
        amount_received_total: {
            name: 'Общая полученная сумма с учетом налогов, ₽',
            helper: '',
            sort: true
        },
        profit_relation_amount: {
            name: 'Доход по отношению к сумме активов, %',
            helper: '',
            sort: true
        }
        */
    },
    // Актив - График выплат по активу
    assetScheduleItem: {
        number: {
            name: 'Номер купона',
            helper: '',
            sort: true
        },
        date_start: {
            name: 'Начало купонного периода',
            helper: '',
            sort: true
        },
        date_end: {
            name: 'Окончание купонного периода',
            helper: '',
            sort: true
        },
        date_fixed: {
            name: 'Дата фиксации реестра',
            helper: '',
            sort: true
        },
        amount_single: {
            name: 'Выплата на одну облигацию, ₽',
            helper: '',
            sort: true
        },
    },
    // Профиль - Платежи по тарифам
    tariffOrderItem: {
        created: {
            name: 'Дата',
            helper: '',
            sort: true
        },
        price: {
            name: 'Сумма',
            helper: '',
            sort: true
        },
        tariff: {
            name: 'Тариф',
            helper: '',
            sort: true
        },
        period: {
            name: 'Период оплаты',
            helper: '',
            sort: true
        },
        action_code_name: {
            name: 'Статус',
            helper: '',
            sort: true
        /*
        },
        has_payment: {
            name: '',
            helper: '',
        */
        },
    },
    // Доверительное управление - Портфели в управлении
    trustManagerItem: {
        user_public_name: {
            name: 'Инвестор',
            helper: '',
            sort: true
        },
        portfolio_name: {
            name: 'Брокерский счет',
            helper: '',
            sort: true
        },
        comment: {
            name: 'Комментарий',
            helper: '',
            //sort: true
        },
        action: {
        },
    },
    // Скринер облигаций
    bondScreenerItem: {
        action: {
        },
        name: {
            name: 'Название / Код',
            synonim: 'isin',
            helper: '',
            //join: ['instrument_ticker', 'instrument_isin'],
            sort: true,
            disabled: true
        },
        portfolio_shares: {
            name: 'Доля актива',
            helper: '',
            sort: true
        },
        currency: {
            name: 'Валюта номинала',
            helper: '',
            sort: true
        },
        price: {
            name: 'Цена текущая',
            helper: '',
            sort: true
        },
        price_percent: {
            name: 'Цена, % от номинала',
            helper: '',
            sort: true
        },
        aci_value: {
            name: 'НКД',
            helper: '',
            sort: true
        },
        coupon_amount: {
            name: 'Купон до выплаты налога',
            helper: '',
            sort: true
        },
        coupon_amount_tax: {
            name: 'Купон после выплаты налога',
            helper: '',
            sort: true,
            hideDefault: true
        },
        coupon_frequency: {
            name: 'Частота выплат в год',
            helper: '',
            sort: true
        },
        year_income: {
            name: 'Доход 1, %',
            helper: 'screener_input_year_income',
            sort: true
        },
        close_income: {
            name: 'Доход 2, %',
            helper: 'screener_input_close_income',
            sort: true
        },
        total_income: {
            name: 'Общий доход, %',
            helper: 'screener_input_total_income',
            sort: true
        },
        coupon_date: {
            name: 'Дата купона',
            helper: '',
            sort: true
        },
        coupon_days_until_next: {
            name: 'Дней до следующего купона',
            helper: '',
            sort: true
        },
        coupon_days_after: {
            name: 'Дней после купона',
            helper: '',
            sort: true
        },
        issue_date: {
            name: 'Дата начала торгов',
            helper: '',
            sort: true
        },
        maturity_date: {
            name: 'Дата погашения',
            helper: '',
            sort: true
        },
        maturity_years: {
            name: 'Лет до погашения',
            helper: '',
            sort: true
        },
        valume_trading: {
            name: 'Объем торгов, млн.',
            helper: '',
            sort: true
        },
        duration: {
            name: 'Дюрация, лет',
            helper: '',
            sort: true,
            hideDefault: true
        },
        nominal_feature: {
            name: 'Особые условия номинала',
            helper: '',
            sort: true
        },
        coupon_feature: {
            name: 'Особые условия купона',
            helper: '',
            sort: true
        },
        security_type: {
            name: 'Вид ценной бумаги',
            helper: '',
            sort: true
        },
        qualified: {
            name: 'Для квал.инвесторов',
            helper: '',
            sort: true
        },
        offer_date: {
            name: 'Дата оферты на MOEX',
            helper: '',
            sort: true
        },
        rating: {
            name: 'Рейтинг эмитента',
            helper: '',
            sort: true
        },
        has_default: {
            name: 'Дефолт',
            helper: '',
            sort: true
        },
    },
    // Скринер облигаций. Облигация. График выплат купонов
    bondCouponItem: {
        start_date: {
            name: 'Дата начала купонного периода',
            helper: '',
            sort: true,
            hideDefault: true
        },
        pay_date: {
            name: 'Дата выплаты купона',
            helper: '',
            sort: true
        },
        record_date: {
            name: 'Фиксация списка держателей "Купить за 2 РАБОЧИХ дня до этой даты"',
            helper: '',
            sort: true
        },
        nominal: {
            name: 'Номинал облигации',
            helper: '',
            sort: true
        },
        rate: {
            name: 'Ставка купона от номинала, % годовых',
            helper: '',
            sort: true
        },
        amount: {
            name: 'Размер выплаты в валюте номинала',
            helper: '',
            sort: true
        },
        amortization_rate: {
            name: 'Амортизация, % от номинала',
            helper: '',
            sort: true
        },
        amortization_amount: {
            name: 'Амортизация, сумма',
            helper: '',
            sort: true
        },
    },
    // Скринер облигаций. Списки облигаций
    bondScreenerListItem: {
        name: {
            name: 'Название списка',
            helper: '',
            sort: true
        },
        updated: {
            name: 'Дата изменения',
            helper: '',
            sort: true
        },
        counts: {
            name: 'Число активов',
            helper: '',
            sort: true
        },
        action: {
        },
    },
    // Watchlist
    WatchlistItem: {
        action: {
        },
        name: {
            name: 'Название / Код',
            synonim: 'isin',
            helper: '',
            //join: ['instrument_ticker', 'instrument_isin'],
            sort: true,
            disabled: true
        },
        quantity: {
            name: 'Кол-во',
            helper: '',
            sort: true
        },
        portfolio_shares: {
            name: 'Доля актива',
            helper: '',
            sort: true
        },
        share_in_list: {
            name: 'Доля в WL, %',
            helper: '',
            sort: true
        },
        share_year_income: {
            name: 'Доход 1 от доли в WL, %',
            helper: '',
            sort: true
        },
        emitter_branch: {
            name: 'Отрасль',
            helper: '',
            sort: true
        },
        price: {
            name: 'Цена текущая',
            helper: '',
            sort: true
        },
        purchase_amount: {
            name: 'Сумма покупки',
            helper: '',
            sort: true
        },
        aci_value: {
            name: 'НКД',
            helper: '',
            sort: true
        },
        purchase_amount_aci: {
            name: 'Сумма покупки с НКД',
            helper: '',
            sort: true
        },

        coupon_amount: {
            name: 'Ближайший купон, 1 шт. до выплаты налога',
            helper: '',
            sort: true
        },
        coupon_amount_tax: {
            name: 'Ближайший купон, 1 шт. после выплаты налога',
            helper: '',
            sort: true
        },
        coupon_frequency: {
            name: 'Частота выплат в год',
            helper: '',
            sort: true
        },
        year_income: {
            name: 'Доход 1, %',
            helper: '',
            sort: true
        },
        close_income: {
            name: 'Доход 2, %',
            helper: '',
            sort: true
        },
        total_income: {
            name: 'Общий доход в год погашения, %',
            helper: '',
            sort: true
        },
        maturity_date: {
            name: 'Дата погашения',
            helper: '',
            sort: true
        },
        maturity_years: {
            name: 'Лет до погашения',
            helper: '',
            sort: true
        },
        coupon_date: {
            name: 'Дата ближайшего купона',
            helper: '',
            sort: true
        },
        coupon_days_until_next: {
            name: 'Дней до следующего купона',
            helper: '',
            sort: true
        },
        coupon_days_after: {
            name: 'Дней после купона',
            helper: '',
            sort: true
        },
        nominal_feature: {
            name: 'Особенности номинала',
            helper: '',
            sort: true
        },
        amortization_date: {
            name: 'Дата ближайшей выплаты по АД',
            helper: '',
            sort: true
        },
        amortization_amount: {
            name: 'Сумма АД, за 1 шт. в валюте номинала',
            helper: '',
            sort: true
        },
        coupon_feature: {
            name: 'Особые условия купона',
            helper: '',
            sort: true
        },
        emitter: {
            name: 'Название компании',
            helper: '',
            sort: true
        },
        emitter_inn: {
            name: 'ИНН',
            helper: '',
            sort: true
        },
        emitter_foundation_year: {
            name: 'Год основания',
            helper: '',
            sort: true
        },
        rating: {
            name: 'Рейтинг компании',
            helper: '',
            sort: true
        },
        has_default: {
            name: 'Дефолт',
            helper: '',
            sort: true
        },
        purchase_date: {
            name: 'Дата покупки',
            helper: '',
            sort: true
        },
        comment: {
            name: 'Комментарий',
            helper: '',
            //sort: true
        },
    },
    reminderItem: {
        reminder_date: {
            name: 'Дата напоминания',
            helper: '',
            sort: true
        },
        content: {
            name: 'Сообщение',
            helper: '',
            sort: true
        },
        portfolio: {
            name: 'Брокерский счет / Инструмент',
            helper: '',
            sort: true
        },
        action: {
        },
    }
};

const defaultBondScreenerFilter = {
    q: null,
    //currency_all: false,
    //feature_all: false,
    security_type_all: false,
    tax: '13',
    currency: 1,
    rating: ['A', 'B'],
    nominal_feature: ['fixed', 'amortization'],
    coupon_feature: ['fixed'],
    security_type: ['ofz_bond', 'corporate_bond', 'exchange_bond'],
    has_price: true,
    qualified: false,
    has_default: ['not'],
    has_offer: -1
};

const defaultWatchListFilter = {
    q: null,
    tax: '13',
    rating: ['A', 'B'],
    nominal_feature: ['fixed', 'amortization'],
    coupon_feature: ['fixed'],
    has_default: ['not']
};

const onPageList = [{
    name: '10 строк',
    value: 10
}, {
    name: '25 строк',
    value: 25
}, {
    name: '50 строк',
    value: 50
}, {
    name: '100 строк',
    value: 100
}];

export {
    fieldNames,
    defaultBondScreenerFilter,
    defaultWatchListFilter,
    onPageList
};